import { createFileRoute } from '@tanstack/react-router'

import { RouterWhen } from '@tk/frontend/primitives'

export const Route = createFileRoute('/admin')({
  component: Surface,
})

function Surface() {
  return <RouterWhen can="super" />
}
