import { ReactNode } from 'react'

import { Typography } from '@mui/material'
import styled from 'styled-components'

export type WrappedTextCellProps = {
  text?: string
}

const OuterWrapper = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
`

export const InnerWrapper = styled.div`
  overflow: hidden;
  white-space: normal;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
  width: 100%;
`

interface ContainerProps {
  children: ReactNode
}

export const WrappedTextCellContainer = ({ children }: ContainerProps) => {
  return (
    <OuterWrapper>
      <InnerWrapper>{children}</InnerWrapper>
    </OuterWrapper>
  )
}

export function WrappedTextCell({ text }: WrappedTextCellProps) {
  return (
    <WrappedTextCellContainer>
      <Typography variant="body2">{text}</Typography>
    </WrappedTextCellContainer>
  )
}
