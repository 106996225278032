import { useMemo } from 'react'

import * as Icons from '@mui/icons-material'
import {
  Box,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material'

import { MarketGroupListingItemDto } from '@lib/pgs'
import {
  ButtonLink,
  getFormComponents,
  To,
  When,
} from '@tk/frontend/primitives'

export type Props = {
  marketGroups: MarketGroupListingItemDto[]
}

const Form = getFormComponents<never>()

export function CommercialPackagesSection({ marketGroups }: Props) {
  const packages = useMemo(() => {
    return marketGroups.filter(
      (mg) => mg.marketCategory?.type === 'COMMERCIAL_PACKAGE'
    )
  }, [marketGroups])

  const packageUris = useMemo(
    () =>
      packages.map<To>((row) => ({
        to: '/record-management/commercial-packages',
        search: { filter: row.description },
      })),
    [packages]
  )
  const recordsUris = useMemo(
    () =>
      packages.map<To>((row) => ({
        to: '/record-management/records',
        search: { 'initial-marketgroup': row.id },
      })),
    [packages]
  )

  return (
    <Form.Section title="Commercial Packages">
      <Box display="flex" flexDirection="column" alignItems="center">
        {packages.length === 0 && <strong>No commercial packages!</strong>}

        {packages.length > 0 && (
          <TableContainer>
            <Table size="small">
              <TableBody>
                {packages.map((r, i) => (
                  <TableRow key={r.id}>
                    <TableCell>
                      <strong>{r.description}</strong>
                    </TableCell>

                    <TableCell>
                      <Stack
                        direction="row"
                        spacing={1}
                        justifyContent="flex-end"
                      >
                        <ButtonLink
                          {...recordsUris[i]}
                          target="_blank"
                          variant="outlined"
                          color="secondary"
                          size="small"
                          endIcon={<Icons.OpenInNew />}
                        >
                          Records
                        </ButtonLink>

                        <When flag="commercialPackages">
                          <ButtonLink
                            {...packageUris[i]}
                            target="_blank"
                            variant="outlined"
                            color="secondary"
                            size="small"
                            endIcon={<Icons.OpenInNew />}
                          >
                            Package
                          </ButtonLink>
                        </When>
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>
    </Form.Section>
  )
}
