import { useMemo } from 'react'

import * as Icons from '@mui/icons-material'
import { Button } from '@mui/material'
import { useModal } from '@parameta/nice-modal-react'

import { ButtonLink, When } from '@tk/frontend/primitives'
import {
  ActionButtonGroup,
  GridEnrichedColDef,
} from '@tk/frontend/primitives/datagrid'
import {
  SecondaryHeader,
  SecondaryTextCell,
} from '@tk/frontend/primitives/datagrid/cells'
import { setColumnDefaults } from '@tk/frontend/primitives/datagrid/columns'

import * as CategoryUIs from './Category'
import * as PackageUIs from './Package'
import { TreeItem } from './types'

export function useCommercialPackageColumns() {
  const packageModal = useModal(PackageUIs.UpsertModal)
  const categoryModal = useModal(CategoryUIs.UpsertModal)

  return useMemo<GridEnrichedColDef<TreeItem>[]>(() => {
    const cols: GridEnrichedColDef<TreeItem>[] = [
      {
        type: 'string',
        field: 'id',
        headerName: 'ID',
        width: 70,
      },
      {
        type: 'string',
        field: 'name',
        headerName: 'Name (readonly)',
        width: 300,
        renderHeader(params) {
          return <SecondaryHeader text={params.colDef.headerName!} />
        },
        renderCell(params) {
          return <SecondaryTextCell text={params.value} />
        },
      },
      {
        type: 'string',
        field: 'description',
        headerName: 'Description',
        minWidth: 300,
        flex: 1,
      },
      {
        type: 'string',
        field: 'scope',
        headerName: 'Scope',
        width: 100,
      },
    ]

    const crudActions: GridEnrichedColDef<TreeItem> = {
      type: 'actions',
      field: 'crud-actions',
      width: 200,
      align: 'right',
      getActions(params) {
        const row = params.row

        if (row._type === 'market-category') {
          return [
            <When can="commercial-packages.manage">
              <ActionButtonGroup>
                <Button
                  color="primary"
                  startIcon={<Icons.Add />}
                  onClick={() => {
                    packageModal.show({
                      type: 'create',
                      categoryId: row.id,
                    })
                  }}
                  data-testid="add-package-button"
                >
                  Add
                </Button>

                <Button
                  startIcon={<Icons.Edit />}
                  onClick={() => {
                    categoryModal.show({
                      type: 'update',
                      updateId: row.id,
                    })
                  }}
                  data-testid="edit-category-button"
                >
                  Edit
                </Button>
              </ActionButtonGroup>
            </When>,
          ]
        } else {
          return [
            <When can="commercial-packages.manage">
              <ActionButtonGroup>
                <Button
                  startIcon={<Icons.Edit />}
                  onClick={() => {
                    packageModal.show({
                      type: 'update',
                      updateId: row.id,
                      categoryId: row.parent.id,
                    })
                  }}
                  data-testid="edit-package-button"
                >
                  Edit
                </Button>
              </ActionButtonGroup>
            </When>,
          ]
        }
      },
    }

    const packageActions: GridEnrichedColDef<TreeItem> = {
      type: 'actions',
      field: 'nav-actions',
      flex: 1,
      align: 'left',
      getActions(params) {
        if (params.row._type === 'market-group') {
          return [
            <ActionButtonGroup>
              <ButtonLink
                color="primary"
                startIcon={<Icons.TableRowsRounded />}
                variant="text"
                to="/record-management/records"
                search={{ 'initial-marketgroup': params.row.id }}
              >
                View
              </ButtonLink>
            </ActionButtonGroup>,
          ]
        }

        return []
      },
    }

    return cols.map(setColumnDefaults).concat([crudActions, packageActions])
  }, [categoryModal, packageModal])
}
