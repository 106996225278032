import * as Icons from '@mui/icons-material'
import { Box, Button, TextField } from '@mui/material'
import { DataGridPremium, useGridApiRef } from '@mui/x-data-grid-premium'
import { useModal } from '@parameta/nice-modal-react'

import { trpc } from '@tk/frontend/api'
import { When } from '@tk/frontend/primitives'
import {
  defaultProps,
  useFilterQueryParams,
} from '@tk/frontend/primitives/datagrid'
import {
  TreeDataGridStyles,
  useTreeGroupingProps,
} from '@tk/frontend/primitives/datagrid/tree'
import { PageContentLayout } from '@tk/frontend/primitives/layout'

import * as CategoryUIs from './Category'
import { MarketCategory, TreeItem } from './types'
import { useCommercialPackageColumns } from './useCommercialPackageColumns'

const ARRAY: any[] = []

export function CommercialPackagesPage() {
  const filter = useFilterQueryParams()

  const [list, listQuery] =
    trpc.commercialPackages.listCategories.useSuspenseQuery(
      {
        filter: filter.debouncedValue,
      },
      {
        select(data) {
          return queryToTree(data)
        },
      }
    )

  const columns = useCommercialPackageColumns()

  const api = useGridApiRef()
  const groupingProps = useTreeGroupingProps(api, {
    getTreeDataPath,
    getRowId,
  })
  const modal = useModal(CategoryUIs.UpsertModal)

  return (
    <PageContentLayout
      title="Commercial Packages"
      controls={
        <When can="commercial-packages.manage">
          <Button
            startIcon={<Icons.Add />}
            variant="contained"
            onClick={() => modal.show({ type: 'create' })}
          >
            Create
          </Button>
        </When>
      }
    >
      <Box display="flex" flexDirection="column" height="100%" width="100%">
        <TextField
          value={filter.value}
          onChange={(e) => filter.setValue(e.target.value)}
          label="Filter"
          placeholder="You may use wildcards (%) to search for a Category or Commercial Package"
          variant="filled"
          fullWidth
          inputProps={{
            role: 'search',
          }}
        />

        <TreeDataGridStyles>
          <DataGridPremium<TreeItem>
            {...defaultProps}
            apiRef={api}
            //
            initialState={{
              columns: {
                columnVisibilityModel: {
                  id: false,
                },
              },
              density: 'compact',
            }}
            //
            {...groupingProps}
            //
            rows={list ?? ARRAY}
            columns={columns}
            loading={listQuery.isFetching}
          />
        </TreeDataGridStyles>
      </Box>
    </PageContentLayout>
  )
}

function queryToTree(marketCategories: MarketCategory[]): TreeItem[] {
  const result: TreeItem[] = []

  for (const marketCategory of marketCategories) {
    result.push({
      _type: 'market-category',
      ...marketCategory,
    })

    result.push(
      ...(marketCategory.marketGroups ?? []).map<TreeItem>((marketGroup) => {
        return {
          _type: 'market-group',
          ...marketGroup,
          parent: marketCategory,
        }
      })
    )
  }

  return result
}

function getTreeDataPath(row: TreeItem): string[] {
  if (row._type === 'market-category') {
    return [row.name]
  } else {
    return [row.parent.name, row.name]
  }
}

function getRowId(row: TreeItem) {
  return `${row._type}/${row.id}`
}
