import { useMemo } from 'react'

import * as Icons from '@mui/icons-material'
import { Box, TextField } from '@mui/material'

import { trpc, useUtils } from '@tk/frontend/api'
import {
  useBloombergMappingTableTelemetry,
  useBloombergMappingTelemetry,
} from '@tk/frontend/app/Bloomberg/telemetry'
import { PackageActions } from '@tk/frontend/app/Bloomberg/types'
import { ButtonLink, useEntitlement } from '@tk/frontend/primitives'
import { ActionButtonGroup } from '@tk/frontend/primitives/datagrid'
import {
  EditableDefaults,
  NamedBusinessObjectTable,
} from '@tk/frontend/primitives/datagrid/table'
import { useClientFilter } from '@tk/frontend/primitives/datagrid/useClientFilter'
import { PageContentLayout } from '@tk/frontend/primitives/layout'

import { PricingSourcesCreateButton } from './PricingSourcesCreateButton'

export function PricingSourcesCrud() {
  useBloombergMappingTableTelemetry('bloomberg/pricing-sources')

  return (
    <>
      <PageContentLayout
        title="Bloomberg: Pricing Sources"
        controls={
          <>
            <PricingSourcesCreateButton />
          </>
        }
      >
        <PricingSourcesCrudTable />
      </PageContentLayout>
    </>
  )
}

function PricingSourcesCrudTable() {
  const { trackEditSave, trackEditStart } = useBloombergMappingTelemetry(
    'bloomberg/pricing-sources'
  )

  const utils = useUtils()

  const mutator = trpc.bloomberg.pricingSource.upsert.useMutation({
    onMutate(variables) {
      trackEditSave('not-tracked', variables)
    },
    onSuccess() {
      utils.bloomberg.pricingSource.invalidate()
    },
  })
  const [list] = trpc.bloomberg.pricingSource.list.useSuspenseQuery()

  const canManage = useEntitlement('bloomberg-mappings.manage')

  const { filteredList, filterValue, setFilterValue } = useClientFilter(list)

  const packageActions = useMemo<PackageActions>(() => {
    return [
      {
        type: 'actions',
        field: 'nav-actions',
        flex: 1,
        align: 'left',
        getActions(params) {
          return [
            <ActionButtonGroup>
              <ButtonLink
                color="primary"
                startIcon={<Icons.TableRowsRounded />}
                variant="text"
                to="/bloomberg/records-mappings"
                search={{
                  'initial-pricing-source-ids': params.row.id,
                }}
              >
                Mappings
              </ButtonLink>
            </ActionButtonGroup>,
          ]
        },
      },
    ]
  }, [])

  return (
    <Box display="flex" flexDirection="column" height="100%" width="100%">
      <TextField
        value={filterValue ?? ''}
        onChange={(e) => setFilterValue(e.target.value)}
        label="Filter"
        variant="filled"
        inputProps={{
          role: 'search',
        }}
        placeholder='You may use wildcards (%) to search for partial Name and Description. ie. "%ICAP%"'
        fullWidth
      />

      <NamedBusinessObjectTable
        list={filteredList}
        onEditStart={trackEditStart}
        onEditSave={mutator.mutateAsync}
        editable={canManage && EditableDefaults}
        packageActions={packageActions}
      />
    </Box>
  )
}
