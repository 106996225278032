import { createFileRoute, redirect } from '@tanstack/react-router'

export const Route = createFileRoute('/')({
  beforeLoad() {
    // Nothing on the root right now, we just redirect to records
    throw redirect({
      to: '/record-management/records',
    })
  },
})
