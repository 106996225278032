import { useCallback } from 'react'

import type { RecordEditDto } from '@tk/frontend/app/Records/common/crud'

import { useTanstackRouteEffect } from '@lib/web'
import { telemetry } from '@tk/frontend/telemetry'
import * as Forms from '@tk/frontend/telemetry/forms'

type DirtyFields = Forms.RhfDirtyFields<RecordEditDto>
type ErrorFields = Forms.RhfErrorFields<RecordEditDto>

export function useRecordEditTelemetry(
  mode: 'create' | 'update',
  record: Partial<RecordEditDto>
) {
  useTanstackRouteEffect(() => {
    telemetry.capture(
      'gtk/record-edit/start',
      mode === 'create'
        ? {
            crudEntity: 'record',
            mode: mode,
          }
        : {
            crudEntity: 'record',
            mode: mode,
            entityId: record.id!,
            entityName: record.name!,
          }
    )
  })

  const trackValidationFailed = useCallback(
    (_dirtyFields: DirtyFields, errors: ErrorFields) => {
      console.warn('VALIDATE FAIL', { _dirtyFields, errors })

      const dirtyFields = Forms.getDirtyFields(_dirtyFields)

      const { errorFields, errorMessages } = Forms.getFieldErrors(errors)

      telemetry.capture(
        'gtk/record-edit/validate-fail',
        mode === 'create'
          ? {
              crudEntity: 'record',
              mode: mode,
              errorFields: errorFields,
              errorMessages: errorMessages,
            }
          : {
              crudEntity: 'record',
              mode: mode,
              entityId: record.id!,
              entityName: record.name!,
              errorFields: errorFields,
              errorMessages: errorMessages,
              dirtyFields: dirtyFields,
            }
      )
    },
    [mode, record.id, record.name]
  )

  const trackSaved = useCallback(
    (newVersion: Partial<RecordEditDto>, _dirtyFields: DirtyFields) => {
      const dirtyFields = Forms.getDirtyFields(_dirtyFields)

      telemetry.capture(
        'gtk/record-edit/save',
        mode === 'create'
          ? {
              crudEntity: 'record',
              mode: mode,
              entityId: newVersion.id!,
              entityName: newVersion.name!,
            }
          : {
              crudEntity: 'record',
              mode: mode,
              entityId: newVersion.id!,
              entityName: newVersion.name!,
              dirtyFields: dirtyFields,
            }
      )
    },
    [mode]
  )

  return {
    trackValidationFailed,
    trackSaved,
  } as const
}
