import { useEffect } from 'react'

import * as Icons from '@mui/icons-material'
import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
} from '@mui/material'
import { create, useModal } from '@parameta/nice-modal-react'
import { useNavigate } from '@tanstack/react-router'

import { useSimpleImportContext } from './SimpleImportContext'
import { usePreviewUrl } from './usePreviewUrl'

export type ImportModel = {
  phase: 'processing' | 'applying'
  taskId: number
}

export const ModalRenderer = create<ImportModel>((model) => {
  switch (model.phase) {
    case 'processing': {
      return <ImportProgress {...model} />
    }
    case 'applying': {
      return <div>Not Implemented</div>
    }
  }
})

function ImportProgress({ taskId }: ImportModel) {
  const modal = useModal()
  const { route } = useSimpleImportContext()

  const previewUri = usePreviewUrl(taskId)

  const [status] = route.status.useSuspenseQuery(
    { taskId },
    { refetchInterval: 500 }
  )

  const navigate = useNavigate()
  useEffect(() => {
    if (!status) {
      return
    }

    if (status.state === 'READY_TO_PERSIST' || status.state === 'FAILED') {
      modal.hide()
      navigate(previewUri)
    }
  }, [modal, navigate, previewUri, status])

  const data = status
  if (!data) {
    return null
  }

  return (
    <Dialog open={modal.visible} fullWidth maxWidth="xs">
      <DialogTitle>
        Import
        <IconButton
          sx={{ position: 'absolute', right: 8, top: 8 }}
          onClick={modal.hide}
        >
          <Icons.Close />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        {(data.state === 'NEW' || data.state === 'GENERATING_PREVIEW') && (
          <Stack direction="row" alignItems="center">
            <CircularProgress size={16} thickness={10} />

            <span>
              Working... {data.processedCount}/{data.totalCount}
            </span>
          </Stack>
        )}

        {(data.state === 'CANCELLED' || data.state === 'FAILED') && (
          <Stack direction="row" alignItems="center">
            <CircularProgress size={16} thickness={10} />

            <span>
              {data.state}: {data.errorMessage ?? 'Unknown Error'}
            </span>
          </Stack>
        )}
      </DialogContent>
    </Dialog>
  )
}
