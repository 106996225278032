import React, { ReactNode, useState } from 'react'

import * as Icons from '@mui/icons-material'
import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
} from '@mui/material'
import { Link } from '@tanstack/react-router'

import { To } from '@tk/frontend/primitives/routing'
import { telemetry } from '@tk/frontend/telemetry'

export type NavSectionProps = {
  title: string
  children: ReactNode
}

export function NavSection({ title, children }: NavSectionProps) {
  return (
    <div data-testid="sidebar-section" data-title={title}>
      <Divider />

      <Box data-testid="sidebar-section-title" padding="1rem 0 0 1rem">
        <Typography variant="subtitle2">{title}</Typography>
      </Box>

      <List data-testid="sidebar-section-links" role="menubar">
        {children}
      </List>
    </div>
  )
}

export type NavButtonProps = {
  label: string
  path: To['to']
  icon?: ReactNode
  disabled?: boolean
}
export function NavButton({
  label,
  icon = <Icons.FiberManualRecordOutlined />,
  disabled = false,
  path,
}: Readonly<NavButtonProps>) {
  const [cachedIcon] = useState(icon)

  return (
    <ListItem disablePadding>
      <Link
        to={path}
        style={{
          width: '100%',
          textDecoration: 'none',
          color: 'inherit',
          cursor: disabled ? 'default' : undefined,
        }}
        onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
          if (disabled) {
            e.preventDefault()
          }

          telemetry.capture('gtk/nav-button-click', {
            route: path?.toString() ?? label,
          })
        }}
        data-testid="sidebar-nav-button"
        role="menuitem"
        aria-disabled={disabled || !path}
      >
        {(innerProps) => (
          <LinkInner
            {...innerProps}
            disabled={disabled || !path}
            label={label}
            icon={cachedIcon}
          />
        )}
      </Link>
    </ListItem>
  )
}

interface LinkInnerProps {
  isActive: boolean
  isTransitioning: boolean
  disabled: boolean
  label: string
  icon?: ReactNode
}
function LinkInner_({ isActive, disabled, label, icon }: LinkInnerProps) {
  const theme = useTheme()

  return (
    <ListItemButton
      color="primary"
      sx={{
        paddingY: '0.25rem',
      }}
      style={{
        backgroundColor: isActive ? theme.palette.primary.main : undefined,
        color: isActive ? theme.palette.primary.contrastText : undefined,
      }}
      disabled={disabled}
      component="div"
    >
      <ListItemIcon
        sx={{
          minWidth: '1.5rem',
          maxWidth: '1.5rem',
          marginRight: '10px',
          color: 'inherit',
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        {icon}
      </ListItemIcon>

      <ListItemText primary={label} data-testid="sidebar-nav-button-text" />
    </ListItemButton>
  )
}
const LinkInner = React.memo(LinkInner_)
