import React, { useRef, useState } from 'react'

import {
  Box,
  CircularProgress,
  MenuItem,
  Paper,
  Popper,
  TextField,
} from '@mui/material'

import { RecordListingItemDto } from '@lib/pgs'
import { trpc } from '@tk/frontend/api'

export type SimpleRecordSearchProps = {
  onSelect: (record: RecordListingItemDto) => void
  label: string
  placeholder: string
  errorMessage?: string
}

function useFilteredList(filter: string | undefined) {
  return trpc.records.list.useQuery(
    {
      filter: '%' + filter + '%' || '',
      size: 30,
      sort: 'name',
      direction: 'asc',
    },
    {
      select(data): RecordListingItemDto[] {
        return data.content || []
      },
    }
  )
}

export const SimpleRecordSearch: React.FC<SimpleRecordSearchProps> = ({
  onSelect,
  placeholder,
  errorMessage,
  label,
}) => {
  const [state, setState] = useState({
    filter: '',
    isFocused: false,
  })

  const anchorRef = useRef<HTMLDivElement | null>(null)

  const { data: options = [], isLoading } = useFilteredList(state.filter)

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState((prev) => ({ ...prev, filter: e.target.value }))
  }

  const handleSelect = (option: RecordListingItemDto) => {
    onSelect(option)
    setState({ filter: option.name, isFocused: false })
  }

  const handleFocus = () => setState((prev) => ({ ...prev, isFocused: true }))
  const handleBlur = () =>
    setTimeout(() => setState((prev) => ({ ...prev, isFocused: false })), 150)

  return (
    <Box
      position="relative"
      ref={anchorRef}
      sx={{
        width: '100%',
        display: 'block',
      }}
    >
      {/* Search Field */}
      <TextField
        variant="outlined"
        placeholder={placeholder}
        value={state.filter}
        onChange={handleInputChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        error={!!errorMessage}
        helperText={errorMessage}
        InputProps={{
          endAdornment: isLoading ? <CircularProgress size={20} /> : null,
          style: { height: '40px', fontSize: '16px' },
        }}
        fullWidth
        sx={{
          '& .MuiOutlinedInput-root': { height: '40px' },
          '& .MuiFormHelperText-root': {
            fontSize: '10px',
            color: 'error.main',
            marginLeft: '0px',
          },
        }}
      />

      {/* Dropdown Suggestions using Popper */}
      <Popper
        open={state.isFocused && options.length > 0}
        anchorEl={anchorRef.current}
        placement="bottom-start"
        style={{ zIndex: 1300 }}
      >
        <Paper
          sx={{
            maxHeight: '400px',
            overflowY: 'auto',
            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
            borderRadius: '4px',
            zIndex: 1300,
          }}
        >
          {options.map((option) => (
            <MenuItem
              key={option.id}
              onClick={() => handleSelect(option)}
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                padding: '8px 16px',
                cursor: 'pointer',
              }}
            >
              {option.name || 'Unnamed Record'}
            </MenuItem>
          ))}
        </Paper>
      </Popper>
    </Box>
  )
}
