import React, { ReactElement, ReactNode } from 'react'

import { Typography } from '@mui/material'

export type TwoLineTextCellProps = {
  label?: ReactNode
  line1?: ReactNode
  line2?: ReactNode
}

export function TwoLineTextCell({ label, line1, line2 }: TwoLineTextCellProps) {
  return (
    <div>
      {label && (
        <Typography variant="inherit" color="text.secondary">
          {label}
        </Typography>
      )}
      {line1 && wrapIfStringy(line1, <Typography variant="body2" />)}

      {line2 &&
        line1 !== line2 &&
        wrapIfStringy(line2, <Typography variant="body2" color="GrayText" />)}
    </div>
  )
}

function wrapIfStringy(
  value: ReactNode,
  Wrapper: ReactElement<{ children: ReactNode }>
) {
  if (typeof value === 'string' || typeof value === 'number') {
    return React.cloneElement(Wrapper, { children: value })
  } else {
    return value
  }
}
