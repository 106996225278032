import { useMemo } from 'react'

import { emulateDbFilter } from '@lib/utils'
import { useDebounce } from '@lib/web'

import { useFilterQueryParams } from './useFilterQueryParams'

type ClientFilterType = {
  name: string
  description?: string
}

export function useClientFilter<T extends ClientFilterType>(initialList: T[]) {
  const { value: filterValue, setValue: setFilterValue } =
    useFilterQueryParams()

  const debouncedValue = useDebounce(filterValue, 400)
  const filteredList = useMemo(() => {
    return initialList.filter(
      (row) =>
        emulateDbFilter(debouncedValue, row.name) ||
        emulateDbFilter(debouncedValue, row.description)
    )
  }, [initialList, debouncedValue])

  return { filteredList, filterValue, setFilterValue }
}
