import { useMemo } from 'react'

import { Box, TextField } from '@mui/material'
import { DataGridPremium } from '@mui/x-data-grid-premium'

import { FidDto, System, SystemLabels } from '@tk/frontend/app/Fids/types'
import {
  defaultProps,
  GridEnrichedColDef,
  useColumnVisibilityModel,
} from '@tk/frontend/primitives/datagrid'
import {
  NameDateCell,
  WrappedTextCell,
} from '@tk/frontend/primitives/datagrid/cells'
import {
  createColumns,
  setColumnDefaults,
} from '@tk/frontend/primitives/datagrid/columns'
import { useClientFilter } from '@tk/frontend/primitives/datagrid/useClientFilter'

export type FidsTableProps = {
  list: FidDto[]
}

export function FidsTable({ list }: FidsTableProps) {
  const cols = useMemo<GridEnrichedColDef<FidDto>[]>(() => {
    return createColumns<FidDto>([
      {
        type: 'string',
        field: 'id',
        headerName: 'ID',
        width: 70,
        editable: false,
      },
      {
        type: 'string',
        field: 'system',
        headerName: 'System',
        width: 100,
        editable: false,
        valueFormatter(value: System) {
          return SystemLabels[value]
        },
      },
      {
        type: 'string',
        field: 'externalId',
        headerName: 'External ID',
        width: 100,
        editable: false,
      },
      {
        type: 'string',
        field: 'name',
        headerName: 'Name',
        width: 150,
        editable: false,
      },
      {
        type: 'string',
        field: 'description',
        headerName: 'Description',
        flex: 1,
        editable: false,
        renderCell(params) {
          return <WrappedTextCell text={params.value} />
        },
      },
      {
        type: 'string',
        field: 'dataType',
        headerName: 'Data Type',
        width: 100,
        editable: false,
      },
      {
        type: 'string',
        field: 'createdBy',
        headerName: 'Created',
        width: 150,
        editable: false,
        renderCell(params) {
          const { createdBy, createdDate } = params.row
          return <NameDateCell name={createdBy} date={createdDate} />
        },
      },
      {
        type: 'string',
        field: 'modifiedBy',
        headerName: 'Modified',
        width: 150,
        editable: false,
        renderCell(params) {
          const { modifiedBy, modifiedDate } = params.row
          return <NameDateCell name={modifiedBy} date={modifiedDate} />
        },
      },
    ]).map(setColumnDefaults)
  }, [])

  const { filteredList, filterValue, setFilterValue } = useClientFilter(list)
  const columnVisibility = useColumnVisibilityModel<FidDto>({ id: false })

  return (
    <Box display="flex" flexDirection="column" height="100%" width="100%">
      <TextField
        value={filterValue ?? ''}
        onChange={(e) => setFilterValue(e.target.value)}
        label="Filter"
        variant="filled"
        inputProps={{
          role: 'search',
        }}
        placeholder='You may use wildcards (%) to search for partial Name and Description. ie. "%DOLLARS%"'
        fullWidth
      />

      <Box flex="1 1 0" minHeight={0} minWidth={0}>
        <DataGridPremium
          {...defaultProps}
          //
          initialState={{
            sorting: {
              sortModel: [{ field: 'name', sort: 'asc' }],
            },
            density: 'compact',
          }}
          //
          rows={filteredList}
          columns={cols}
          //
          columnVisibilityModel={columnVisibility.model}
          onColumnVisibilityModelChange={columnVisibility.setModel}
        />
      </Box>
    </Box>
  )
}
