import { useCallback } from 'react'

import { useTanstackRouteEffect } from '@lib/web'

import { ReferenceDataEntity } from './events'
import * as forms from './forms'
import { capture } from './posthog'

export type NamedBusinessObjectCore = {
  id: number | string
  name: string
}

function useFormCallbacks(
  crudEntityName: ReferenceDataEntity,
  mode: 'create' | 'update'
) {
  const trackEditStart = useCallback(
    (initialData: Partial<NamedBusinessObjectCore>) => {
      capture(
        `gtk/reference-data/form/show`,
        mode === 'create'
          ? {
              mode,
              crudEntity: crudEntityName,
            }
          : {
              mode,
              crudEntity: crudEntityName,
              entityId: initialData.id!,
              entityName: initialData.name!,
            }
      )
    },
    [crudEntityName, mode]
  )

  const trackEditValidationError = useCallback(
    (
      dirty: 'not-tracked' | forms.RhfDirtyFields<any> = 'not-tracked',
      errors:
        | 'not-tracked'
        | forms.RhfErrorFields<NamedBusinessObjectCore> = 'not-tracked',
      dto: Partial<NamedBusinessObjectCore>
    ) => {
      const dirtyFields = forms.getDirtyFields(dirty)
      const fieldErrors = forms.getFieldErrors(errors)

      capture(
        `gtk/reference-data/form/validation-failed`,
        mode === 'create'
          ? {
              mode,
              crudEntity: crudEntityName,
              errorFields: fieldErrors.errorFields,
              errorMessages: fieldErrors.errorMessages,
            }
          : {
              mode,
              crudEntity: crudEntityName,
              entityId: dto.id!,
              entityName: dto.name!,
              dirtyFields: dirtyFields,
              errorFields: fieldErrors.errorFields,
              errorMessages: fieldErrors.errorMessages,
            }
      )
    },
    [crudEntityName, mode]
  )

  const trackEditSave = useCallback(
    (
      dirty: 'not-tracked' | forms.RhfDirtyFields<any> = 'not-tracked',
      dto: Partial<NamedBusinessObjectCore>
    ) => {
      const dirtyFields = forms.getDirtyFields(dirty)

      capture(
        `gtk/reference-data/form/save`,
        mode === 'create'
          ? {
              mode,
              crudEntity: crudEntityName,
              entityId: dto.id!,
              entityName: dto.name!,
            }
          : {
              mode,
              crudEntity: crudEntityName,
              entityId: dto.id!,
              entityName: dto.name!,
              dirtyFields,
            }
      )
    },
    [crudEntityName, mode]
  )

  return {
    trackEditStart,
    trackEditValidationError,
    trackEditSave,
  }
}

/**
 * Use when presenting a table. Just tracks a show event
 */
export function useReferenceDataCrudTable(crudEntityName: ReferenceDataEntity) {
  useTanstackRouteEffect(() => {
    capture(`gtk/reference-data/table-show`, {
      crudEntity: crudEntityName,
    })
  })
}

/**
 * Use when presenting a table which can be edited in-line. Dispatches show event and returns editing callbacks
 */
export function useReferenceDataEditableTable(
  crudEntityName: ReferenceDataEntity
) {
  useReferenceDataCrudTable(crudEntityName)

  const { trackEditStart, trackEditSave, trackEditValidationError } =
    useFormCallbacks(crudEntityName, 'update')

  return { trackEditStart, trackEditSave, trackEditValidationError }
}

/**
 * Use when presenting form for a single entity. Dispatches an edit-start event and returns other editing callbacks
 */
export function useReferenceDataCrudForm(
  crudEntityName: ReferenceDataEntity,
  initialData: Partial<NamedBusinessObjectCore>
) {
  const mode = initialData.id ? 'create' : 'update'

  const { trackEditStart, trackEditSave, trackEditValidationError } =
    useFormCallbacks(crudEntityName, mode)

  useTanstackRouteEffect(() => {
    trackEditStart(initialData)
  })

  return { trackEditSave, trackEditValidationError }
}
