import { lazy, StrictMode } from 'react'
import * as ReactDOM from 'react-dom/client'

import { createRouter, RouterProvider } from '@tanstack/react-router'
import { SnackbarProvider } from 'notistack'

import { ApiProvider } from './api'
import { getAppSecurity } from './okta'
import { routeTree } from './routeTree.gen'

import './fonts'
import './main.css'
import './mui-x'

import { FullscreenError, FullscreenLoader, UnknownPage } from './primitives'
import { telemetry } from './telemetry'
import { Theme } from './theme'

import './rum'

const { AppSecurity, oktaAuth } = getAppSecurity()

const router = createRouter({
  routeTree,
  context: {
    auth: oktaAuth,
  },
})

export type Router = typeof router

const DevTools = lazy(() =>
  import('./DevTools').then(({ DevTools }) => ({ default: DevTools }))
)

declare module '@tanstack/react-router' {
  interface Register {
    router: Router
  }
}

telemetry.init()

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <StrictMode>
    <Theme>
      <RouterProvider
        router={router}
        defaultPendingComponent={FullscreenLoader}
        defaultErrorComponent={FullscreenError}
        defaultNotFoundComponent={UnknownPage}
        InnerWrap={({ children }) => (
          <AppSecurity>
            <ApiProvider>
              <SnackbarProvider
                maxSnack={5}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              >
                {children}
              </SnackbarProvider>

              {import.meta.env.DEV && <DevTools />}
            </ApiProvider>
          </AppSecurity>
        )}
      />
    </Theme>
  </StrictMode>
)
