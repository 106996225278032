import { Dialog, DialogContent } from '@mui/material'
import { create, useModal } from '@parameta/nice-modal-react'

import { RecordDto } from '@lib/pgs'
import { useUtils } from '@tk/frontend/api'

import { LinkRecordsForm } from './LinkRecordsForm'

type Model = { record: RecordDto; onFinished?: () => void }

export const LinkRecordsModal = create<Model>(() => {
  const modal = useModal()
  const utils = useUtils()

  const handleFinished = () => {
    modal.hide()
    utils.records.invalidate()
  }

  return (
    <Dialog
      open={modal.visible}
      onClose={modal.hide}
      sx={{
        '& .MuiDialog-paper': {
          width: '100%',
          maxWidth: '800px',
          margin: 0,
        },
      }}
    >
      <DialogContent>
        <LinkRecordsForm onFinished={handleFinished} />
      </DialogContent>
    </Dialog>
  )
})
