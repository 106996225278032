import { useDeferredValue } from 'react'

import { useDebounce } from '@lib/web'
import { useQueryParam } from '@tk/frontend/primitives/useQueryParam'

type Options = {
  name?: string
  defaultValue?: string
}

export function useFilterQueryParams(opts?: Options) {
  const [value, setValue] = useQueryParam(opts?.name ?? 'filter', {
    defaultValue: opts?.defaultValue ?? '',
  })

  const deferredValue = useDeferredValue(value)
  const debouncedValue = useDebounce(value, 400)

  return {
    value,
    deferredValue: deferredValue,
    debouncedValue: debouncedValue,
    setValue,
  }
}
