import * as Icons from '@mui/icons-material'
import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'

import {
  AppLink,
  InfoPopover,
  SuspenseBoundaryLocal,
} from '@tk/frontend/primitives'

export type TouchedByPopoverProps = {
  touchedByNames: string[]
}

export function TouchedByPopover({ touchedByNames }: TouchedByPopoverProps) {
  return (
    <InfoPopover
      colour="inherit"
      contentContainer={<Stack direction="column" padding="0 0.5rem" />}
      icon={
        <Icons.Launch
          fontSize="inherit"
          color="primary"
          style={{ marginLeft: '0.15rem' }}
        />
      }
      content={
        <SuspenseBoundaryLocal
          width="20rem"
          height={`${touchedByNames.length * 2}rem`}
        >
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Project</TableCell>

                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {touchedByNames.map((name) => {
                return (
                  <TableRow key={name}>
                    <TableCell>{name}</TableCell>

                    <TableCell>
                      <AppLink
                        to="/record-management/records"
                        search={{ 'initial-touched-by-name': name }}
                        target="_blank"
                      >
                        List Records
                        <Icons.Launch
                          fontSize="inherit"
                          style={{
                            marginLeft: '0.15rem',
                            marginBottom: '-2px',
                          }}
                        />
                      </AppLink>
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </SuspenseBoundaryLocal>
      }
    >
      <Typography variant="body2">
        Touched by <strong>{touchedByNames.length}</strong> project
        {touchedByNames.length === 1 ? '' : 's'}
      </Typography>
    </InfoPopover>
  )
}
