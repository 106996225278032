import * as Icons from '@mui/icons-material'
import { Button } from '@mui/material'
import { useModal } from '@parameta/nice-modal-react'

import { ButtonLink } from '@tk/frontend/primitives'
import { ActionButtonGroup } from '@tk/frontend/primitives/datagrid'

import { MutationModal } from './MutationModal'
import { Source } from './types'

export type Props = {
  data: Source
}

export function SourceActions(props: Props) {
  const modal = useModal(MutationModal)

  return (
    <ActionButtonGroup>
      <Button
        startIcon={<Icons.Edit />}
        onClick={() => {
          modal.show({
            type: 'source',
            data: props.data,
          })
        }}
      >
        Edit
      </Button>
      <ButtonLink
        color="primary"
        startIcon={<Icons.TableRowsRounded />}
        variant="text"
        to="/record-management/records"
        search={{ 'initial-source': props.data.id }}
      >
        Records
      </ButtonLink>
    </ActionButtonGroup>
  )
}
