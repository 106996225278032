import { Button, ButtonProps } from '@mui/material'
import { LinkComponentProps, useNavigate } from '@tanstack/react-router'
import { isEmpty } from 'lodash'

type RouterProps = {
  to?: LinkComponentProps['to']
  params?: LinkComponentProps['params']
  search?: LinkComponentProps['search']
  target?: string
}

export type ButtonLinkProps = ButtonProps & RouterProps

// This is here as the tanstack router link component has terrible performance when many are loading
// This is an issue on pages, such as the RecordEditPage where we render many in the LinkedDataSection
// As such, we use a basic anchor tag instead and build the href to emulate the same behaviour as before
// There are issues open relating to this: https://github.com/TanStack/router/discussions/2011
const createHref = (routerProps: Omit<RouterProps, 'target'>) => {
  const search = routerProps.search as Record<string, any>
  let url = routerProps.to ?? window.location.pathname

  if (url.includes('$') && routerProps.params && !isEmpty(routerProps.params)) {
    Object.entries(routerProps.params).forEach(([param, value]) => {
      const replacement = new RegExp(`\\$${param}`, 'g')
      url = url.replace(replacement, value)
    })
  }

  if (search && !isEmpty(search)) {
    let queryString = '?'
    Object.entries(search).forEach(([param, value], index) => {
      //Only supports primitive types for now
      if (
        typeof value !== 'string' &&
        typeof value !== 'number' &&
        typeof value !== 'boolean'
      ) {
        return
      }

      if (index > 0) {
        queryString += '&'
      }

      queryString += `${param}=${encodeURIComponent(value)}`
    })

    url += queryString
  }

  return url
}

export function ButtonLink({
  to,
  target,
  children,
  disabled,
  params,
  search,
  ...props
}: ButtonLinkProps) {
  const navigate = useNavigate()
  const isDisabled = disabled || !to

  const buttonInner = (
    <Button
      variant="contained"
      disabled={isDisabled}
      color="primary"
      {...props}
    >
      {children}
    </Button>
  )

  if (isDisabled) {
    return buttonInner
  }

  return (
    <a
      href={createHref({ to, params, search })}
      target={target}
      onClick={(e) => {
        // This is necessary because when used inside a table cell, the table cell hijacks the click handler
        e.stopPropagation()
        e.preventDefault()
        navigate({ to: to ?? '.', params, search })
      }}
      style={{
        textDecoration: 'none',
        cursor: isDisabled ? 'default' : undefined,
      }}
    >
      {buttonInner}
    </a>
  )
}
