import { useLayoutEffect, useRef } from 'react'

import { GridRenderEditCellParams } from '@mui/x-data-grid-premium'

export function useAutoFocus(params: GridRenderEditCellParams<any, any, any>) {
  const inputRef = useRef<HTMLInputElement>(undefined)

  useLayoutEffect(() => {
    if (params.hasFocus) {
      inputRef.current?.focus()
    }
  }, [params.hasFocus])

  return inputRef
}
