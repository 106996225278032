import { createFileRoute } from '@tanstack/react-router'

import { SignOut } from '@lib/okta-web'
import { telemetry } from '@tk/frontend/telemetry'

export const Route = createFileRoute('/sign-out')({
  component: SignOutComponent,
})

function SignOutComponent() {
  return (
    <SignOut
      onSignout={() => {
        telemetry.signout()
      }}
    />
  )
}
