import { keepPreviousData } from '@tanstack/react-query'

import { useDebounce } from '@lib/web'
import { trpc } from '@tk/frontend/api'

import { flattenRecordMappings } from './types'
import { useRecordMappingQueryParams } from './useRecordMappingQueryParams'

export function useRecordMappingsList() {
  const params = useRecordMappingQueryParams()

  const debouncedFilter = useDebounce(params.filter ?? '', 500)
  const listQuery = trpc.bloomberg.mapping.list.useQuery(
    {
      ...params.query,
      filter: debouncedFilter,
    },
    {
      select(data) {
        const page = { ...data }

        return {
          ...page,
          content: flattenRecordMappings(data.content),
        }
      },
      placeholderData: keepPreviousData,
    }
  )

  return listQuery
}
