import { css, styled } from '@mui/material'

interface TreeDataGridStylesProps {
  hasMargin?: boolean
}

export const TREE_PARENT_CLASS = 'tree-parent-row'
export const TREE_CHILD_CLASS = 'tree-child-row'

export const TreeDataGridStyles = styled('div')<TreeDataGridStylesProps>`
  flex: 1 1 0;
  min-height: 0;
  min-width: 0;

  .${TREE_PARENT_CLASS} {
    background-color: ${({ theme }) => theme.palette.grey[50]};

    :hover {
      background-color: ${({ theme }) => theme.palette.grey[200]};
    }
  }

  .${TREE_CHILD_CLASS} {
    :hover {
      background-color: ${({ theme }) => theme.palette.grey[200]};
    }

    position: relative;

    ${({ hasMargin = false }) =>
      hasMargin &&
      css`
        margin-left: 1rem;
      `}
  }

  /* Force the scroll bar by default to avoid popping when expanding tree items */
  & .MuiDataGrid-virtualScroller {
    overflow-y: scroll;
  }
`
