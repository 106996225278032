import { createFileRoute } from '@tanstack/react-router'

import { RouterWhen } from '@tk/frontend/primitives'

export const Route = createFileRoute('/record-management/commercial-packages')({
  component: Surface,
})

function Surface() {
  return <RouterWhen can="commercial-packages.read" flag="commercialPackages" />
}
