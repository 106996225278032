import * as Icons from '@mui/icons-material'
import { Button } from '@mui/material'
import { useNavigate } from '@tanstack/react-router'

import { trpc, useUtils } from '@tk/frontend/api'
import {
  getFormComponents,
  usePromiseNotification,
} from '@tk/frontend/primitives'

import { RrnStructureForm } from './RrnStructureForm'
import { transformFromFormData } from './transformFromFormData'
import { RrnStructureFormData } from './types'

const Form = getFormComponents<RrnStructureFormData>()

export function RrnStructureCreatePage() {
  const form = Form.useForm({
    defaultValues: {
      marketInstruments: [],
      status: 'active',
    },
  })

  const navigate = useNavigate()
  const notify = usePromiseNotification()
  const createMutation = trpc.multibrand.rrnStructure.create.useMutation({
    onSuccess(data) {
      navigate({
        to: '/multibrand/root-record-name-structure/edit/$id',
        params: { id: data.id },
      })
    },
  })
  const utils = useUtils()

  const handleSubmit = form.handleSubmit(async (values) => {
    const requestDto = transformFromFormData(values)

    await notify(createMutation.mutateAsync(requestDto), {
      progressMessage: `Creating Root Record Name Structure`,
      successMessage: `Created Root Record Name Structure`,
      failureMessage: `Failed to create Root Record Name Structure`,
    })

    utils.multibrand.invalidate()
  })

  return (
    <Form.Provider {...form}>
      <Form.FormStack onSubmit={handleSubmit} submitOnCtrlEnter>
        <Form.SectionTitleRow title="Create Root Record Name Structure">
          <Button
            startIcon={<Icons.Save />}
            type="submit"
            variant="contained"
            disabled={createMutation.isPending || createMutation.isSuccess}
          >
            Create
          </Button>
        </Form.SectionTitleRow>

        <RrnStructureForm />
      </Form.FormStack>
    </Form.Provider>
  )
}
