import * as Icons from '@mui/icons-material'

import { MenuItemLink } from '@tk/frontend/primitives/MenuItemLink'
import { telemetry } from '@tk/frontend/telemetry'

export function ExportListButton() {
  return (
    <MenuItemLink
      to="/record-management/records/exports"
      startIcon={<Icons.List />}
      onClick={() => {
        telemetry.capture('gtk/record-export/list')
      }}
    >
      Exports List
    </MenuItemLink>
  )
}
