import {
  CircularProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import { useSearch } from '@tanstack/react-router'

import { trpc } from '@tk/frontend/api'
import { NameDateCell } from '@tk/frontend/primitives/datagrid/cells'
import { PageContentLayout } from '@tk/frontend/primitives/layout'
import { theme } from '@tk/frontend/theme'

import { ExportDownloadButton } from './ExportDownloadButton'
import {
  ExportProcessingStates,
  ExportStateColour,
  ExportStateLabel,
} from './types'

export function ExportProgressList() {
  const searchParams = useSearch({ from: '/record-management/records/exports' })
  const focusedTaskId = searchParams?.focus
  const [exportsList, exportsListQuery] =
    trpc.records.export.list.useSuspenseQuery(undefined, {
      refetchInterval: 2500,
      refetchIntervalInBackground: true,
    })

  return (
    <PageContentLayout
      title="Record Exports"
      controls={
        <CircularProgress
          sx={{ opacity: exportsListQuery.isRefetching ? 1 : 0 }}
          size="1.5rem"
          thickness={10}
          variant="indeterminate"
        />
      }
    >
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell width={300}>Task ID</TableCell>
              <TableCell width={300}>Submitted</TableCell>
              <TableCell width={200}>Processed</TableCell>
              <TableCell width={200}>Status</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {exportsList.map((item) => {
              return (
                <TableRow
                  key={item.taskId}
                  style={{
                    borderLeft:
                      focusedTaskId === item.taskId
                        ? `5px solid ${theme.palette.primary.main}`
                        : undefined,
                    backgroundColor:
                      focusedTaskId === item.taskId
                        ? theme.palette.grey[300]
                        : undefined,
                  }}
                >
                  <TableCell>{item.taskId}</TableCell>

                  <TableCell>
                    <NameDateCell
                      name={item.submittedBy}
                      date={item.submittedAt}
                    />
                  </TableCell>

                  <TableCell>
                    {item.processedCount}/{item.totalCount}
                  </TableCell>

                  <TableCell>
                    <Stack
                      direction="row"
                      color={ExportStateColour[item.state]}
                    >
                      {ExportProcessingStates.includes(item.state) && (
                        <CircularProgress size="1rem" thickness={10} />
                      )}

                      <strong>
                        {ExportStateLabel[item.state] ??
                          'Unknown: ' + item.state}
                      </strong>
                    </Stack>
                  </TableCell>

                  <TableCell>
                    {item.state === 'COMPLETED' && (
                      <ExportDownloadButton taskId={item.taskId} />
                    )}
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </PageContentLayout>
  )
}
