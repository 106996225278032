import { z } from 'zod'

import * as PGS from '@lib/pgs'
import { Require, Simplify } from '@lib/utils'

import { testAssignable } from './util'

//
// Type Literals and Enums
//

export const ImportStatus = z.union([
  z.literal('NEW'),
  z.literal('GENERATING_PREVIEW'),
  z.literal('READY_TO_PERSIST'),
  z.literal('PERSISTING'),
  z.literal('COMPLETED'),
  z.literal('FAILED'),
  z.literal('CANCELLED'),
])
testAssignable<
  (typeof ImportStatus)['_input'],
  Exclude<PGS.ImportTaskDto['state'], undefined>
>()
testAssignable<PGS.ImportTaskDto['state'], (typeof ImportStatus)['_output']>()

//
// Import Query Types
//

export const ImportQueryDto = z.object({
  filter: z
    .string()
    .optional()
    .transform((filter) => {
      if (!filter) {
        return undefined
      }
      return filter
    }),
  page: z.number().optional(),
  size: z.number().default(30),
  sort: z.union([z.literal('id'), z.literal('name')]).default('id'),
  direction: z.union([z.literal('asc'), z.literal('desc')]).default('desc'),
  states: z.array(ImportStatus).optional(),
  submittedAtOrAfter: z
    .date()
    .default(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)),
  submittedBefore: z.date().optional(),
})

//
// Zod ImportPreview Types
//

type OverrideVersionDtoTypes<T extends object, TVersion> = Simplify<{
  [key in keyof T]: key extends 'currentVersion' | 'newVersion'
    ? TVersion
    : T[key]
}>

type BaseImportPreviewDto<T> = Omit<
  OverrideVersionDtoTypes<PGS.ImportPreviewDtoTickerPreviewDto, T>,
  'rowObjectRelation'
>

export interface ImportPreviewOneToOne<T>
  extends Require<BaseImportPreviewDto<T>, 'rowNumber' | 'line'> {
  rowObjectRelation: 'ONE_TO_ONE'
}
export interface ImportPreviewManyToOne<T>
  extends Exclude<BaseImportPreviewDto<T>, 'rowNumber' | 'line'> {
  rowObjectRelation: 'MANY_TO_ONE'
}

export type ImportPreview<T> =
  | ImportPreviewOneToOne<T>
  | ImportPreviewManyToOne<T>

export type ImportPreviewInput<T> = OverrideVersionDtoTypes<
  PGS.ImportPreviewDtoTickerPreviewDto,
  T
>
