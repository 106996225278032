import * as Icons from '@mui/icons-material'
import { Button } from '@mui/material'
import { useParams } from '@tanstack/react-router'

import { Inputs, trpc, useUtils } from '@tk/frontend/api'
import {
  getFormComponents,
  usePromiseNotification,
} from '@tk/frontend/primitives'

import { AssetTypeFormFields } from './AssetTypeFormFields'

const Form = getFormComponents<Inputs['reference']['assetType']['update']>()

export const AssetTypeUpdateFormPage = () => {
  const { id } = useParams({ from: '/reference-data/asset-types/edit/$id' })
  const [data] = trpc.reference.assetType.get.useSuspenseQuery({
    id,
  })

  const utils = useUtils()
  const form = Form.useForm({
    defaultValues: data,
  })

  const notify = usePromiseNotification()

  const updateAssetTypeMutation = trpc.reference.assetType.update.useMutation()

  const onSubmit = form.handleSubmit(async (values) => {
    await notify(updateAssetTypeMutation.mutateAsync(values), {
      progressMessage: `Updating Asset Type`,
      successMessage: `Updated Asset Type Successfully`,
      failureMessage: `Failed to update Asset Type`,
    })

    utils.reference.assetType.get.invalidate()
  })

  return (
    <Form.Provider {...form}>
      <Form.FormStack onSubmit={onSubmit} submitOnCtrlEnter>
        <Form.SectionTitleRow title="Update Asset Type">
          <Button
            startIcon={<Icons.Save />}
            type="submit"
            variant="contained"
            disabled={false}
          >
            Save
          </Button>
        </Form.SectionTitleRow>

        <AssetTypeFormFields />

        <input {...form.register('id')} hidden />
      </Form.FormStack>
    </Form.Provider>
  )
}
