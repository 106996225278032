import { ReactNode, useMemo } from 'react'
import {
  ErrorBoundary,
  ErrorBoundaryPropsWithRender,
} from 'react-error-boundary'

import type { RouteInterface, RouterDefinition } from '@tk/api'
import type {
  inferRouterError,
  inferRouterInputs,
  inferRouterOutputs,
} from '@trpc/server'

import { decodeToken } from '@okta/okta-auth-js'
import {
  QueryClient,
  QueryClientProvider,
  QueryErrorResetBoundary,
} from '@tanstack/react-query'
import { createTRPCReact, httpBatchLink, loggerLink } from '@trpc/react-query'
import * as superjson from 'superjson'

import { useOktaAuth } from '@lib/okta-web'
import { environment } from '@tk/frontend/environment'

import { FullscreenError } from './primitives'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 1000,
    },
    mutations: {
      retry: 3,
      retryDelay: 500,
    },
  },
})

export const trpc = createTRPCReact<RouterDefinition>()

const { useUtils } = trpc

export type Inputs = inferRouterInputs<RouterDefinition>
export type Outputs = inferRouterOutputs<RouterDefinition>

export type RouterError = inferRouterError<RouterDefinition>

export { RouterDefinition, useUtils, RouteInterface }

export const ApiProvider = (props: { children: ReactNode }) => {
  const auth = useOktaAuth()

  const trpcClient = useMemo(() => {
    async function getAuthHeader() {
      console.debug('[getAuthHeader] called')
      const token = await auth.oktaAuth.getOrRenewAccessToken()
      if (token) {
        const expiry = decodeToken(token).payload.exp
        console.debug(
          '[getAuthHeader] token expires',
          expiry ? new Date(expiry * 1000) : 'undefined'
        )

        return token
      }

      // If we're not currently being redirected back with an auth code, redirect for sign in
      if (!auth.oktaAuth.isLoginRedirect()) {
        await auth.oktaAuth.signInWithRedirect({
          originalUri: window.location.href,
        })

        // Don't continue to an API call, just wait for the redirect to kill the page
        await new Promise((resolve) => setTimeout(resolve, 10000))
      }

      return 'UNSET'
    }

    return trpc.createClient({
      links: [
        loggerLink({
          enabled: () => import.meta.env.DEV,
        }),
        httpBatchLink({
          url: environment.api.url,
          headers: async () => {
            const token = await getAuthHeader()

            return {
              authorization: `Bearer ${token}`,
            }
          },
          transformer: superjson,
          // API Gateway has a max length and may return a 414 error when this is breached
          // But it's not clearly documented and doesn't appear to show up in logs
          // Through some trial and error with the batching on the Field Group edit page we've landed on this as a safe but high value to enable batching without errors
          maxURLLength: 5200,
        }),
      ],
    })
  }, [auth.oktaAuth])

  return (
    <trpc.Provider queryClient={queryClient} client={trpcClient}>
      <QueryClientProvider client={queryClient}>
        {props.children}
      </QueryClientProvider>
    </trpc.Provider>
  )
}

export const QueryErrorBoundary = (
  props: Partial<ErrorBoundaryPropsWithRender> & { children: any }
) => {
  return (
    <QueryErrorResetBoundary>
      {({ reset }) => {
        return (
          <ErrorBoundary
            fallbackRender={
              props.fallbackRender ??
              (({ error, resetErrorBoundary }) => (
                <FullscreenError error={error} reset={resetErrorBoundary} />
              ))
            }
            {...props}
            onReset={reset}
          />
        )
      }}
    </QueryErrorResetBoundary>
  )
}
