import { ReactNode, useRef, useState } from 'react'

import { Button, ButtonProps, Menu } from '@mui/material'

export interface MenuButtonProps {
  label: string
  children: ReactNode
  icon: ReactNode
  variant?: ButtonProps['variant']
  color?: ButtonProps['color']
}

export function MenuButton({
  label,
  children,
  icon,
  color = 'secondary',
  variant = 'text',
}: MenuButtonProps) {
  const [open, setOpen] = useState<false | Element>(false)
  const ref = useRef<any>(undefined)

  return (
    <>
      <Button
        ref={ref}
        variant={variant}
        startIcon={icon}
        color={color}
        onClick={(e) => setOpen(e.currentTarget)}
      >
        {label}
      </Button>

      <Menu
        anchorEl={ref.current || open || undefined}
        open={!!open}
        onClose={() => setOpen(false)}
      >
        {children}
      </Menu>
    </>
  )
}
