import { useMemo } from 'react'
import { get } from 'react-hook-form'

import * as Icons from '@mui/icons-material'
import { Button } from '@mui/material'
import {
  GridColDef,
  GridRenderEditCellParams,
  GridRowModes,
} from '@mui/x-data-grid-premium'
import _ from 'lodash'

import { TYPE_OPTIONS } from '@tk/frontend/app/Bloomberg/types'
import { AppLink, When } from '@tk/frontend/primitives'
import {
  ActionButtonGroup,
  GridEnrichedColDef,
  useRowModes,
} from '@tk/frontend/primitives/datagrid'
import {
  BooleanCell,
  SecondaryHeader,
  TwoLineTextCell,
  WrappedTextCell,
} from '@tk/frontend/primitives/datagrid/cells'
import { setColumnDefaults } from '@tk/frontend/primitives/datagrid/columns'

import { DeleteMappingButton } from './DeleteMappingButton'
import { EntitlementsEditCell } from './EntitlementsEditCell'
import { FilterKey, FILTERS_CONFIG } from './filters-config'
import { PricingSourceEditCell } from './PricingSourceEditCell'
import { ProcessEditCell } from './ProcessEditCell'
import { RulesetEditCell } from './RulesetEditCell'
import { TickerEditCell } from './TickersEditCell'
import { TreeItem } from './types'
import { sortByKeys } from './useRecordMappingQueryParams'

export function useColumns() {
  const { rowModes, setEditMode, setViewMode } = useRowModes<TreeItem>()

  const columns = useMemo<GridEnrichedColDef<TreeItem>[]>(() => {
    const recordCols: GridEnrichedColDef<TreeItem>[] = [
      {
        type: 'string',
        field: 'id',
        hideable: true,
        headerName: 'ID',
        valueGetter(value, row) {
          if (row._type === 'record') {
            return value
          }
          return null
        },
      },
      {
        type: 'string',
        field: 'name',
        headerName: 'Record',
        width: 275,
        renderCell(params) {
          if (params.row._type === 'record') {
            return (
              <TwoLineTextCell
                line1={
                  params.row.type === 'CHAIN' ? (
                    <AppLink
                      to="/record-management/records"
                      search={{ 'initial-chain': params.row.id }}
                    >
                      {params.row.name}
                    </AppLink>
                  ) : (
                    params.row.name
                  )
                }
                line2={params.row.source?.name}
              />
            )
          }
          return null
        },
      },
    ]

    const mappingCols: GridEnrichedColDef<TreeItem>[] = [
      {
        type: 'singleSelect',
        field: 'ticker',
        headerName: 'Ticker / Object ID',
        width: 200,
        editable: true,
        valueGetter(value, row, column) {
          if (row._type === 'record') {
            return _(row.bloombergMappings)
              .map((mapping) => get(mapping, column.field + '.name'))
              .uniq()
              .sort()
              .join(', ')
          } else {
            return get(row, column.field)
          }
        },
        renderCell(params) {
          if (params.row._type === 'record') {
            return <WrappedTextCell text={params.value} />
          } else {
            const ticker = params.row.ticker
            if (!ticker) {
              return <></>
            }

            let name = ticker.name
            if (ticker.objectId) {
              name += ` (${ticker.objectId})`
            }

            return <TwoLineTextCell line1={name} line2={ticker.description} />
          }
        },
        renderEditCell(params) {
          return (
            <TickerEditCell params={params as GridRenderEditCellParams<any>} />
          )
        },
      },
      {
        type: 'singleSelect',
        field: 'pricingSource',
        headerName: 'Pricing Source (PCS)',
        width: 150,
        editable: true,
        valueGetter(value, row, column) {
          if (row._type === 'record') {
            return _(row.bloombergMappings)
              .map((mapping) => get(mapping, column.field + '.name'))
              .uniq()
              .sort()
              .join(', ')
          } else {
            return get(row, column.field)
          }
        },
        renderCell(params) {
          if (params.row._type === 'record') {
            return <WrappedTextCell text={params.value} />
          } else {
            return (
              <TwoLineTextCell
                line1={params.value.name}
                line2={params.value.description}
              />
            )
          }
        },
        renderEditCell(params) {
          return (
            <PricingSourceEditCell
              params={params as GridRenderEditCellParams<any>}
            />
          )
        },
      },
      {
        type: 'singleSelect',
        field: 'entitlement',
        headerName: 'Entitlement (EID)',
        width: 150,
        editable: true,
        valueGetter(value, row, column) {
          if (row._type === 'record') {
            return _(row.bloombergMappings)
              .map((mapping) => get(mapping, column.field + '.name'))
              .uniq()
              .sort()
              .join(', ')
          } else {
            return get(row, column.field)
          }
        },
        renderCell(params) {
          if (params.row._type === 'record') {
            return <WrappedTextCell text={params.value} />
          } else {
            return (
              <TwoLineTextCell
                line1={params.value.name}
                line2={params.value.description}
              />
            )
          }
        },
        renderEditCell(params) {
          return (
            <EntitlementsEditCell
              params={params as GridRenderEditCellParams<any>}
            />
          )
        },
      },
      {
        type: 'singleSelect',
        field: 'entitlement.type',
        headerName: 'Entitlement Meta',
        renderHeader: (params) => (
          <SecondaryHeader text={params.colDef.headerName!} />
        ),
        width: 125,
        editable: false,
        hideable: true,
        valueGetter(value, row, column) {
          if (row._type !== 'record') {
            return get(row, column.field)
          }
          return null
        },
        renderCell(params) {
          if (params.row._type !== 'record' && params.row.entitlement) {
            return (
              <TwoLineTextCell
                line1={
                  TYPE_OPTIONS.find((t) => t.value === params.value)?.label ??
                  ''
                }
                line2={
                  params.row.entitlement.allToSee ? 'All To See' : 'None To See'
                }
              />
            )
          }
          return null
        },
      },
      {
        type: 'singleSelect',
        field: 'process',
        headerName: 'Process',
        width: 150,
        editable: true,
        valueGetter(value, row, column) {
          if (row._type === 'record') {
            return _(row.bloombergMappings)
              .map((mapping) => get(mapping, column.field + '.name'))
              .uniq()
              .sort()
              .join(', ')
          } else {
            return get(row, column.field)
          }
        },
        renderCell(params) {
          if (params.row._type === 'record') {
            return <WrappedTextCell text={params.value} />
          } else {
            if (!params.value) {
              return
            }
            return (
              <TwoLineTextCell
                line1={params.value.name}
                line2={params.value.description}
              />
            )
          }
        },
        renderEditCell(params) {
          return (
            <ProcessEditCell params={params as GridRenderEditCellParams<any>} />
          )
        },
      },
      {
        type: 'singleSelect',
        field: 'ruleset',
        headerName: 'Ruleset',
        width: 150,
        editable: true,
        valueGetter(value, row, column) {
          if (row._type === 'record') {
            return _(row.bloombergMappings)
              .map((mapping) => get(mapping, column.field + '.name'))
              .uniq()
              .sort()
              .join(', ')
          } else {
            return get(row, column.field)
          }
        },
        renderCell(params) {
          if (params.row._type === 'record') {
            return <WrappedTextCell text={params.value} />
          } else {
            if (!params.value) {
              return
            }
            return (
              <TwoLineTextCell
                line1={params.value.name}
                line2={params.value.description}
              />
            )
          }
        },
        renderEditCell(params) {
          return (
            <RulesetEditCell params={params as GridRenderEditCellParams<any>} />
          )
        },
      },
      {
        type: 'boolean',
        field: 'retiredAt',
        headerName: 'Retired',
        renderHeader: (params) => (
          <SecondaryHeader text={params.colDef.headerName!} />
        ),
        width: 100,
        editable: false,
        hideable: true,
        valueGetter(value, row, column) {
          if (row._type !== 'record') {
            return get(row, column.field)
          }
          return null
        },
        renderCell(params) {
          if (params.row._type !== 'record') {
            return <BooleanCell value={params.value} activeColor="secondary" />
          }
          return ''
        },
      },
    ]

    return recordCols
      .concat(mappingCols, [
        {
          type: 'actions',
          field: 'actions',
          align: 'right',
          width: 220,
          getActions({ id, row }) {
            const isInEditMode = rowModes[id]?.mode === GridRowModes.Edit

            function handleEditClick() {
              setEditMode(id, 'ticker')
            }

            function handleSaveClick() {
              setViewMode(id, 'save')
            }
            function handleCancelClick() {
              setViewMode(id, 'ignore')
            }

            if (row._type === 'record') {
              return [
                // Can add group controls here
              ]
            } else if (row.retiredAt) {
              return [
                // retired records don't need any actions
              ]
            } else {
              if (isInEditMode) {
                return [
                  <ActionButtonGroup>
                    <Button
                      color="primary"
                      startIcon={<Icons.Save />}
                      onClick={handleSaveClick}
                    >
                      Save
                    </Button>

                    <Button
                      startIcon={<Icons.Cancel />}
                      onClick={handleCancelClick}
                    >
                      Cancel
                    </Button>
                  </ActionButtonGroup>,
                ]
              } else {
                return [
                  <When can="bloomberg-mappings.manage">
                    <ActionButtonGroup>
                      <Button
                        color="primary"
                        startIcon={<Icons.Edit />}
                        onClick={handleEditClick}
                      >
                        Edit
                      </Button>

                      <DeleteMappingButton
                        recordId={row.recordId}
                        mappingId={row.id}
                      />
                    </ActionButtonGroup>
                  </When>,
                ]
              }
            }
          },
        },
      ])
      .map((col) => {
        col.sortable = sortByKeys.includes(col.field as any)
        col.valueGetter ??= (
          value: never,
          row: Record<string, unknown>,
          column: GridColDef
        ) => {
          return get(row, column.field)
        }

        const filterConfig = FILTERS_CONFIG[col.field as FilterKey]
        if (filterConfig) {
          col.filterable = true
          Object.assign(col, filterConfig.colDef)
        }

        return col
      })
      .map(setColumnDefaults)
  }, [rowModes, setEditMode, setViewMode])

  return {
    rowModes,
    setEditMode,
    setViewMode,
    columns,
  }
}
