import { Button, Stack } from '@mui/material'
import { useNavigate } from '@tanstack/react-router'

import { FidGroupRequestDto } from '@lib/pgs'
import { trpc, useUtils } from '@tk/frontend/api'
import {
  getFormComponents,
  usePromiseNotification,
} from '@tk/frontend/primitives'

const Form = getFormComponents<FidGroupRequestDto>()

export type Props = {
  data: Partial<FidGroupRequestDto>
  onFinished?: () => void
}

export function FidGroupCloneForm({ data = {}, onFinished }: Props) {
  const form = Form.useForm()

  const navigate = useNavigate()
  const notify = usePromiseNotification()
  const utils = useUtils()

  const createMutation = trpc.fids.groups.create.useMutation()

  const handleSubmit = form.handleSubmit(async (values) => {
    const result = await notify(
      createMutation.mutateAsync({
        ...values,
        fidMaps: data.fidMaps ?? [],
        notes: data.notes ?? '',
      }),
      {
        progressMessage: `Creating Clone Field Group from ${data.name}`,
        successMessage: `Created Field Group`,
        failureMessage: `Failed to create Field Group`,
      }
    )

    // We had a bug where the redirection to the view/edit page would not work after the clone is complete

    // To fix this we need to delay the invalidation by a chunk of time so that the page redirection can complete before we invalidate the data

    // https://scm.tpicapcloud.com/p-de/apps/application-monorepo/-/issues/560

    setTimeout(() => {
      utils.fids.groups.invalidate()
    }, 500)

    navigate({
      to: '/fids/groups/edit/$id',
      params: { id: result.id },
    })

    onFinished?.()
  })

  return (
    <Form.Provider {...form}>
      <Stack width="30rem" component="form" onSubmit={handleSubmit}>
        <Form.Field
          label="New name (Required)"
          name="name"
          input={<Form.TextField autoFocus rules={Form.rules.required} />}
        />
        <Form.Field
          label="New description (Required)"
          name="description"
          input={<Form.TextField rules={Form.rules.required} />}
        />

        <Form.Row>
          <Button type="submit">Clone</Button>
        </Form.Row>
      </Stack>
    </Form.Provider>
  )
}
