import { useMemo } from 'react'

import * as Icons from '@mui/icons-material'
import { Box, TextField } from '@mui/material'
import { DataGridPremium } from '@mui/x-data-grid-premium'

import { ButtonLink } from '@tk/frontend/primitives'
import {
  defaultProps,
  GridEnrichedColDef,
} from '@tk/frontend/primitives/datagrid'
import {
  BooleanCell,
  NameDateCell,
} from '@tk/frontend/primitives/datagrid/cells'
import { useClientFilter } from '@tk/frontend/primitives/datagrid/useClientFilter'
import { useReferenceDataEditableTable } from '@tk/frontend/telemetry/reference-data-crud'

import { CurrencyDto } from './types'

export type CurrencyTableProps = {
  list: CurrencyDto[]
}

export function CurrencyTable({ list }: CurrencyTableProps) {
  useReferenceDataEditableTable('currency')

  const cols = useMemo<GridEnrichedColDef<CurrencyDto>[]>(() => {
    return [
      {
        type: 'string',
        field: 'id',
        headerName: 'ID',
        width: 70,
        editable: false,
      },
      {
        type: 'string',
        field: 'name',
        headerName: 'Name',
        flex: 1,
        editable: false,
      },
      {
        type: 'string',
        field: 'description',
        headerName: 'Description',
        flex: 1,
        editable: false,
      },
      {
        type: 'boolean',
        field: 'nonIsoCurrency',
        headerName: 'Is ISO',
        width: 75,
        editable: false,
        valueGetter(value) {
          return !value
        },
        renderCell(params) {
          return <BooleanCell value={params.value} />
        },
      },
      {
        type: 'string',
        field: 'createdBy',
        headerName: 'Created',
        width: 150,
        editable: false,
        renderCell(params) {
          const { createdBy, createdDate } = params.row
          return <NameDateCell name={createdBy} date={createdDate} />
        },
      },
      {
        type: 'string',
        field: 'modifiedBy',
        headerName: 'Modified',
        width: 150,
        editable: false,
        renderCell(params) {
          const { modifiedBy, modifiedDate } = params.row
          return <NameDateCell name={modifiedBy} date={modifiedDate} />
        },
      },
      {
        type: 'actions',
        field: '',
        width: 200,
        align: 'right',
        getActions(params) {
          return [
            <ButtonLink
              color="primary"
              startIcon={<Icons.TableRowsRounded />}
              variant="text"
              to="/record-management/records"
              search={{ 'initial-currency': params.row.id }}
            >
              Records
            </ButtonLink>,
          ]
        },
      },
    ]
  }, [])

  const { filteredList, filterValue, setFilterValue } = useClientFilter(list)

  return (
    <Box display="flex" flexDirection="column" height="100%" width="100%">
      <TextField
        value={filterValue ?? ''}
        onChange={(e) => setFilterValue(e.target.value)}
        label="Filter"
        variant="filled"
        inputProps={{
          role: 'search',
        }}
        placeholder='You may use wildcards (%) to search for partial Name and Description. ie. "%DOLLARS%"'
        fullWidth
      />

      <Box flex="1 1 0" minHeight={0} minWidth={0}>
        <DataGridPremium
          {...defaultProps}
          rows={filteredList}
          columns={cols}
          initialState={{
            sorting: {
              sortModel: [{ field: 'name', sort: 'asc' }],
            },
            density: 'compact',
          }}
        />
      </Box>
    </Box>
  )
}
