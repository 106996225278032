import { useMemo } from 'react'

import { GridRowSelectionModel } from '@mui/x-data-grid-premium'

import { useQueryParam } from '@tk/frontend/primitives/useQueryParam'

export function useRowSelectionModel(queryParamName = 'selected') {
  const [_model, setModel] = useQueryParam<string[]>(queryParamName, {
    defaultValue: [],
  })

  const model = useMemo(() => {
    return _model?.map((textOrNull) => {
      const number = parseInt(textOrNull)

      if (isNaN(number)) {
        return textOrNull
      } else {
        return number
      }
    })
  }, [_model])

  return {
    selectedId: !!model && model.length > 0 ? model[0] : null,
    model: model as (string | number)[],
    setModel: setModel as (val: GridRowSelectionModel) => void,
  }
}
