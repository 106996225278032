import React, { useState } from 'react'

import {
  ClickAwayListener,
  Popover,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { DateTime } from 'luxon'

import { LinkedRecordsDto, RecordDto, RecordListingItemDto } from '@lib/pgs'
import { AppLink } from '@tk/frontend/primitives'
import {
  NameDateCell,
  PairedDateCell,
  TwoLineTextCell,
} from '@tk/frontend/primitives/datagrid/cells'

export type LinkedRecordsPopoverProps = {
  linkedRecords: LinkedRecordsDto[]
  linkText?: string
  record?: RecordListingItemDto | RecordDto
  secondRecord?: RecordListingItemDto | RecordDto
}

export function LinkedRecordsPopover({
  linkedRecords,
  linkText,
  record,
  secondRecord,
}: LinkedRecordsPopoverProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const isOpen = Boolean(anchorEl)

  // Create Link Text when it isn't provided
  const calculatedLinkText =
    linkText ??
    `${
      linkedRecords.length + (record ? 1 : 0) + (secondRecord ? 1 : 0)
    } linked records`

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div>
        <Popover
          open={isOpen}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <Stack direction="column" padding="1rem">
            <Table size="small" style={{ width: '25rem' }}>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ padding: '0.75rem', minWidth: '170px' }}>
                    Name
                  </TableCell>
                  <TableCell sx={{ padding: '0.75rem', minWidth: '120px' }}>
                    Source
                  </TableCell>
                  <TableCell sx={{ padding: '0.75rem', minWidth: '100px' }}>
                    Status
                  </TableCell>
                  <TableCell sx={{ padding: '0.75rem', minWidth: '150px' }}>
                    Valid Dates
                  </TableCell>
                  <TableCell sx={{ padding: '0.75rem', minWidth: '150px' }}>
                    Created Date
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {/* Handle Empty Collection */}
                {!record && !secondRecord && linkedRecords.length === 0 && (
                  <TableRow>
                    <TableCell
                      colSpan={5}
                      sx={{
                        padding: '0.75rem',
                        textAlign: 'center',
                        fontStyle: 'italic',
                        color: 'grey.600',
                      }}
                    >
                      Collection Empty - Record moved to other collection
                    </TableCell>
                  </TableRow>
                )}

                {/* First Record Row */}
                {record && (
                  <TableRow>
                    <TableCell sx={{ padding: '0.75rem', minWidth: '170px' }}>
                      <AppLink
                        to="/record-management/records/edit/$id"
                        params={{ id: record.id.toString() }}
                      >
                        {record.name}
                      </AppLink>
                    </TableCell>
                    <TableCell sx={{ padding: '0.75rem' }}>
                      <TwoLineTextCell
                        line1={record.backbone?.name}
                        line2={record.source?.name}
                      />
                    </TableCell>
                    <TableCell sx={{ padding: '0.75rem' }}>
                      {record.status}
                    </TableCell>
                    <TableCell sx={{ padding: '0.75rem' }}>
                      <PairedDateCell
                        dateOne={
                          record.validFromDate
                            ? DateTime.fromJSDate(record.validFromDate).toISO()
                            : undefined
                        }
                        dateTwo={
                          record.validToDate
                            ? DateTime.fromJSDate(record.validToDate).toISO()
                            : undefined
                        }
                      />
                    </TableCell>
                    <TableCell sx={{ padding: '0.75rem' }}>
                      <NameDateCell
                        name={record.createdBy}
                        date={DateTime.fromJSDate(record.createdDate).toISO()}
                      />
                    </TableCell>
                  </TableRow>
                )}

                {/* Second Record Row */}
                {secondRecord && (
                  <TableRow>
                    <TableCell sx={{ padding: '0.75rem' }}>
                      <AppLink
                        to="/record-management/records/edit/$id"
                        params={{ id: secondRecord.id.toString() }}
                      >
                        {secondRecord.name}
                      </AppLink>
                    </TableCell>
                    <TableCell sx={{ padding: '0.75rem' }}>
                      <TwoLineTextCell
                        line1={secondRecord.backbone?.name}
                        line2={secondRecord.source?.name}
                      />
                    </TableCell>
                    <TableCell sx={{ padding: '0.75rem' }}>
                      {secondRecord.status}
                    </TableCell>
                    <TableCell sx={{ padding: '0.75rem' }}>
                      <PairedDateCell
                        dateOne={
                          secondRecord.validFromDate
                            ? DateTime.fromJSDate(
                                secondRecord.validFromDate
                              ).toISO()
                            : undefined
                        }
                        dateTwo={
                          secondRecord.validToDate
                            ? DateTime.fromJSDate(
                                secondRecord.validToDate
                              ).toISO()
                            : undefined
                        }
                      />
                    </TableCell>
                    <TableCell sx={{ padding: '0.75rem' }}>
                      <NameDateCell
                        name={secondRecord.createdBy}
                        date={
                          secondRecord.createdDate
                            ? DateTime.fromJSDate(
                                secondRecord.createdDate
                              ).toISO()
                            : undefined
                        }
                      />
                    </TableCell>
                  </TableRow>
                )}

                {/* Linked Records Rows */}
                {linkedRecords.map((record) => (
                  <TableRow key={record.id}>
                    <TableCell sx={{ padding: '0.75rem' }}>
                      <AppLink
                        to="/record-management/records/edit/$id"
                        params={{ id: record.id.toString() }}
                      >
                        {record.name}
                      </AppLink>
                    </TableCell>
                    <TableCell sx={{ padding: '0.75rem' }}>
                      <TwoLineTextCell
                        line1={record.backbone?.name}
                        line2={record.source?.name}
                      />
                    </TableCell>
                    <TableCell sx={{ padding: '0.75rem' }}>
                      {record.status}
                    </TableCell>
                    <TableCell sx={{ padding: '0.75rem' }}>
                      <PairedDateCell
                        dateOne={
                          record.validFromDate
                            ? DateTime.fromJSDate(record.validFromDate).toISO()
                            : undefined
                        }
                        dateTwo={
                          record.validToDate
                            ? DateTime.fromJSDate(record.validToDate).toISO()
                            : undefined
                        }
                      />
                    </TableCell>
                    <TableCell sx={{ padding: '0.75rem' }}>
                      <NameDateCell
                        name={record.createdBy}
                        date={record.createdDate}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Stack>
        </Popover>

        <Typography
          variant="body2"
          color="primary"
          style={{ cursor: 'pointer', textDecoration: 'underline' }}
          onClick={handleOpen}
        >
          {calculatedLinkText}
        </Typography>
      </div>
    </ClickAwayListener>
  )
}
