import { GridFilterItem, GridFilterModel } from '@mui/x-data-grid-premium'
import { createFileRoute } from '@tanstack/react-router'
import { isEmpty } from 'lodash'
import { z } from 'zod'

import { FilterKey } from '@tk/frontend/app/Records/filters-config'
import { RecordsTablePage } from '@tk/frontend/app/Records/RecordsTablePage'
import { MultiSelectFilterId } from '@tk/frontend/primitives/datagrid/columns'

const DEFAULT_STATUS_VALUE = ['COLLECT', 'DO_NOT_COLLECT']

const searchParams = z
  .object({
    'filter': z.string().optional(),
    'grid-filters': z.string().optional(),
    'initial-asset-type': z.number().optional(),
    'initial-chain': z.number().optional(),
    'initial-currency': z.number().optional(),
    'initial-fid-group': z.number().optional(),
    'initial-marketgroup': z.number().optional(),
    'initial-touched-by-name': z.string().optional(),
    'initial-source': z.number().optional(),
    'initial-status-items': z.string().optional(),
    'initial-subscription': z.number().optional(),
  })
  .optional()
  .transform((params) => {
    // If no params are present, set the default status
    if (!params || isEmpty(params)) {
      params = {
        'initial-status-items': DEFAULT_STATUS_VALUE.join(','),
      }
    }

    let gridFilters: GridFilterModel = { items: [] }

    if (params?.['grid-filters']) {
      try {
        gridFilters = JSON.parse(window.atob(params['grid-filters']))
      } catch {
        //Do nothing
      }
    }

    if (params['initial-status-items']) {
      const defaultStatusFilter: GridFilterItem = {
        id: -2,
        field: 'status' as FilterKey,
        operator: MultiSelectFilterId,
        value: DEFAULT_STATUS_VALUE,
      }

      gridFilters.items = [
        ...gridFilters.items.filter(
          (it) => it.field !== defaultStatusFilter.field
        ),
        defaultStatusFilter,
      ]
      params['initial-status-items'] = undefined
    }

    if (params['initial-marketgroup'] && params['initial-marketgroup'] > 0) {
      const defaultMarketGroupFilter: GridFilterItem = {
        id: -1,
        field: 'marketGroups' as FilterKey,
        operator: MultiSelectFilterId,
        value: [params['initial-marketgroup']],
      }

      gridFilters.items = [
        ...gridFilters.items.filter(
          (it) => it.field !== defaultMarketGroupFilter.field
        ),
        defaultMarketGroupFilter,
      ]
      params['initial-marketgroup'] = undefined
    }

    if (params['initial-asset-type'] && params['initial-asset-type'] > 0) {
      const defaultAssetTypeFilter: GridFilterItem = {
        id: -1,
        field: 'assetType.name' as FilterKey,
        operator: MultiSelectFilterId,
        value: [params['initial-asset-type']],
      }

      gridFilters.items = [
        ...gridFilters.items.filter(
          (it) => it.field !== defaultAssetTypeFilter.field
        ),
        defaultAssetTypeFilter,
      ]
      params['initial-asset-type'] = undefined
    }

    if (params['initial-chain'] && params['initial-chain'] > 0) {
      const defaultChainFilter: GridFilterItem = {
        id: -1,
        field: 'parentChains' as FilterKey,
        operator: MultiSelectFilterId,
        value: [params['initial-chain']],
      }

      gridFilters.items = [
        ...gridFilters.items.filter(
          (it) => it.field !== defaultChainFilter.field
        ),
        defaultChainFilter,
      ]
      params['initial-chain'] = undefined
    }

    if (params['initial-fid-group'] && params['initial-fid-group'] > 0) {
      const defaultFidGroupFilter: GridFilterItem = {
        id: -1,
        field: 'fidGroup.name' as FilterKey,
        operator: MultiSelectFilterId,
        value: [params['initial-fid-group']],
      }

      gridFilters.items = [
        ...gridFilters.items.filter(
          (it) => it.field !== defaultFidGroupFilter.field
        ),
        defaultFidGroupFilter,
      ]
      params['initial-fid-group'] = undefined
    }

    if (params['initial-touched-by-name']) {
      const defaultTouchedByNamesFilter: GridFilterItem = {
        id: -1,
        field: 'touchedByNames' as FilterKey,
        operator: MultiSelectFilterId,
        value: [params['initial-touched-by-name']],
      }

      gridFilters.items = [
        ...gridFilters.items.filter(
          (it) => it.field !== defaultTouchedByNamesFilter.field
        ),
        defaultTouchedByNamesFilter,
      ]
      params['initial-touched-by-name'] = undefined
    }

    if (params['initial-subscription'] && params['initial-subscription'] > 0) {
      const defaultSubscriptionFilter: GridFilterItem = {
        id: -1,
        field: 'subscription' as FilterKey,
        operator: MultiSelectFilterId,
        value: [params['initial-subscription']],
      }

      gridFilters.items = [
        ...gridFilters.items.filter(
          (it) => it.field !== defaultSubscriptionFilter.field
        ),
        defaultSubscriptionFilter,
      ]
      params['initial-subscription'] = undefined
    }

    if (params['initial-source'] && params['initial-source'] > 0) {
      const defaultInitialSourceFilter: GridFilterItem = {
        id: -1,
        field: 'source.name' as FilterKey,
        operator: MultiSelectFilterId,
        value: [params['initial-source']],
      }

      gridFilters.items = [
        ...gridFilters.items.filter(
          (it) => it.field !== defaultInitialSourceFilter.field
        ),
        defaultInitialSourceFilter,
      ]
      params['initial-source'] = undefined
    }

    if (params['initial-currency'] && params['initial-currency'] > 0) {
      const defaultInitialCurrencyFilter: GridFilterItem = {
        id: -1,
        field: 'currency1.name' as FilterKey,
        operator: MultiSelectFilterId,
        value: [params['initial-currency']],
      }

      gridFilters.items = [
        ...gridFilters.items.filter(
          (it) => it.field !== defaultInitialCurrencyFilter.field
        ),
        defaultInitialCurrencyFilter,
      ]
      params['initial-currency'] = undefined
    }

    if (gridFilters.items.length > 0) {
      params['grid-filters'] = window.btoa(JSON.stringify(gridFilters))
    }

    return params
  })

export const Route = createFileRoute('/record-management/records/')({
  component: RecordsTablePage,
  validateSearch: searchParams.parse,
})
