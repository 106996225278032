import { useMemo } from 'react'

import * as Icons from '@mui/icons-material'
import { Stack } from '@mui/material'
import { DataGridPremium, GridRowsProp } from '@mui/x-data-grid-premium'

import { ButtonLink } from '@tk/frontend/primitives'
import {
  ActionButtonGroup,
  defaultProps,
  GridEnrichedColDef,
} from '@tk/frontend/primitives/datagrid'
import {
  NameDateCell,
  TwoLineTextCell,
} from '@tk/frontend/primitives/datagrid/cells'
import { useReferenceDataEditableTable } from '@tk/frontend/telemetry/reference-data-crud'

import { Subscription } from './types'

export type SubscriptionTableProps = {
  list: Subscription[]
}

export function SubscriptionTable({ list }: SubscriptionTableProps) {
  useReferenceDataEditableTable('subscription')

  const cols = useMemo<GridEnrichedColDef<Subscription>[]>(() => {
    return [
      {
        type: 'string',
        field: 'id',
        headerName: 'ID',
        width: 70,
      },
      {
        type: 'string',
        field: 'accountName',
        headerName: 'Account Name',
        flex: 1,
      },
      {
        type: 'string',
        field: 'backbone',
        headerName: 'Backbone',
        flex: 1,
        valueGetter(value, row) {
          if (row.backbone) {
            return row.backbone.name + row.backbone.description
          }
          return ''
        },
        renderCell(params) {
          return (
            <TwoLineTextCell
              line1={params.row.backbone?.name}
              line2={params.row.backbone?.description}
            />
          )
        },
      },
      {
        type: 'string',
        field: 'recordCount',
        headerName: 'Records',
        flex: 1,
      },
      {
        type: 'string',
        field: 'createdBy',
        headerName: 'Created',
        width: 150,
        renderCell(params) {
          const { createdBy, createdDate } = params.row
          return <NameDateCell name={createdBy} date={createdDate} />
        },
      },
      {
        type: 'string',
        field: 'modifiedBy',
        headerName: 'Modified',
        width: 150,
        renderCell(params) {
          const { modifiedBy, modifiedDate } = params.row
          return <NameDateCell name={modifiedBy} date={modifiedDate} />
        },
      },
      {
        type: 'actions',
        field: 'nav-actions',
        flex: 1,
        align: 'left',
        getActions(params) {
          return [
            <ActionButtonGroup>
              <ButtonLink
                color="primary"
                startIcon={<Icons.TableRowsRounded />}
                variant="text"
                to="/record-management/records"
                search={{ 'initial-subscription': params.row.id }}
              >
                Records
              </ButtonLink>
            </ActionButtonGroup>,
          ]
        },
      },
    ]
  }, [])

  const rows: GridRowsProp<Subscription> = list

  return (
    <Stack flex="1 1 0" minHeight={0} minWidth={0}>
      <DataGridPremium
        {...defaultProps}
        rows={rows}
        columns={cols}
        initialState={{
          sorting: {
            sortModel: [{ field: 'backbone', sort: 'asc' }],
          },
          density: 'compact',
        }}
      />
    </Stack>
  )
}
