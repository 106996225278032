import * as Icons from '@mui/icons-material'

import { When } from '@tk/frontend/primitives'
import { NavButton, NavSection } from '@tk/frontend/primitives/layout/Sidebar'

export const ReferenceDataSidebar = () => {
  return (
    <When can="reference-data.read">
      <NavSection title="Reference Data">
        <NavButton
          label="Asset Classes & Types"
          path="/reference-data/asset-types"
          icon={<Icons.AccountTreeRounded />}
        />
        <NavButton
          label="Clearing Types"
          path="/reference-data/clearing-types"
          icon={<Icons.LabelRounded fontSize="small" />}
        />
        <NavButton
          label="Companies"
          path="/reference-data/companies"
          icon={<Icons.LabelRounded fontSize="small" />}
        />
        <NavButton
          label="Currencies"
          path="/reference-data/currencies"
          icon={<Icons.LabelRounded fontSize="small" />}
        />
        <NavButton
          label="Day Counts"
          path="/reference-data/day-counts"
          icon={<Icons.LabelRounded fontSize="small" />}
        />

        <NavButton
          label="Frequencies"
          path="/reference-data/frequencies"
          icon={<Icons.LabelRounded fontSize="small" />}
        />
        <NavButton
          label="Regions & Locations"
          path="/reference-data/locations"
          icon={<Icons.AccountTreeRounded />}
        />
        <NavButton
          label="Periods"
          path="/reference-data/periods"
          icon={<Icons.LabelRounded fontSize="small" />}
        />
        <NavButton
          label="Price Types"
          path="/reference-data/price-types"
          icon={<Icons.LabelRounded fontSize="small" />}
        />
        <NavButton
          label="Pricing Frequencies"
          path="/reference-data/pricing-frequencies"
          icon={<Icons.LabelRounded fontSize="small" />}
        />
        <NavButton
          label="Settlement Indexes"
          path="/reference-data/settlement-index"
          icon={<Icons.LabelRounded fontSize="small" />}
        />
        <NavButton
          label="Settlement Methods"
          path="/reference-data/settlement-methods"
          icon={<Icons.LabelRounded fontSize="small" />}
        />
        <NavButton
          label="Backbones & Sources"
          path="/reference-data/sources"
          icon={<Icons.AccountTreeRounded />}
        />
        <NavButton
          label="Standard Discountings"
          path="/reference-data/standard-discountings"
          icon={<Icons.LabelRounded fontSize="small" />}
        />
        <NavButton
          label="Subscriptions"
          path="/reference-data/subscriptions"
          icon={<Icons.LabelRounded fontSize="small" />}
        />
      </NavSection>
    </When>
  )
}
