import * as Icons from '@mui/icons-material'

import { ButtonLink, When } from '@tk/frontend/primitives'

export function RrnStructureCreateButton() {
  return (
    <When can="reference-data.manage">
      <ButtonLink
        startIcon={<Icons.Add />}
        variant="contained"
        to="/multibrand/root-record-name-structure/create"
      >
        Create
      </ButtonLink>
    </When>
  )
}
