import { useMemo } from 'react'

import { useSimpleImportContext } from './SimpleImportContext'

export function usePreviewUrl(taskId: number) {
  const { previewUriTemplate } = useSimpleImportContext()

  return useMemo(() => {
    return {
      to: previewUriTemplate,
      params: { taskId: taskId.toString() },
    }
  }, [previewUriTemplate, taskId])
}
