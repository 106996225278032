import { useMemo } from 'react'

import {
  DataGridPremium,
  GridColDef,
  GridRowId,
} from '@mui/x-data-grid-premium'

import {
  createColumns,
  setColumnDefaults,
} from '@tk/frontend/primitives/datagrid/columns'

import { Fids } from './types'

function moveArray(
  array: Array<number | string>,
  from: number,
  to: number,
  on = 1
) {
  const newArray = array.slice()
  const elementsToMove = newArray.splice(from, on)
  newArray.splice(to, 0, ...elementsToMove)

  return newArray
}

type SalesSnapFidOrderingGridProps = {
  loading: boolean
  fids: Fids
  model: GridRowId[]
  setModel: (model: GridRowId[]) => void
}

export function SalesSnapFidOrderingGrid({
  loading,
  fids,
  model,
  setModel,
}: SalesSnapFidOrderingGridProps) {
  const rows = useMemo(() => {
    return model.length > 0 && fids.length > 0
      ? model
          .map((m) => {
            const fid = fids.find((f) => f.rowId === m)
            return fid
              ? {
                  id: fid.rowId,
                  fidName: fid.fidName,
                  acronym: fid.acronym,
                  externalId: fid.externalId,
                }
              : undefined
          })
          .filter(Boolean)
      : []
  }, [fids, model])

  const columns: GridColDef[] = useMemo(
    () =>
      createColumns([
        {
          field: 'fidName',
          headerName: 'Name/Alias',
          width: 150,
          type: 'string',
        },
        {
          field: 'acronym',
          headerName: 'Acronym',
          width: 150,
          type: 'string',
        },
        {
          field: 'externalId',
          headerName: 'External Id',
          width: 150,
          type: 'string',
        },
      ]).map(setColumnDefaults),
    []
  )

  return (
    <DataGridPremium
      sx={{
        marginTop: '0px !important',
      }}
      rowReordering
      onRowOrderChange={(params) => {
        const newRows = moveArray(model, params.oldIndex, params.targetIndex)
        setModel(newRows)
      }}
      loading={loading}
      initialState={{ density: 'compact' }}
      rows={rows}
      columns={columns}
      hideFooter
    />
  )
}
