// Where has the rum gone? It's here

import { AwsRum, AwsRumConfig } from 'aws-rum-web'

import { environment } from '@tk/frontend/environment'

const ENDPOINT = environment.rum?.endpoint
const APP_ID = environment.rum?.appId
const IDENTITY_POOL_ID = environment.rum?.identityPoolId
const APP_VERSION = environment.rum?.appVersion
const APP_REGION = environment.rum?.appRegion
if (!ENDPOINT) {
  console.warn('VITE_RUM_ENDPOINT not set!')
}
if (!IDENTITY_POOL_ID) {
  console.warn('VITE_RUM_APP_ID not set!')
}
if (!APP_ID) {
  console.warn('VITE_RUM_IDENTITY_POOL_ID not set!')
}
if (!APP_VERSION) {
  console.warn('VITE_RUM_APP_VERSION not set!')
}
if (!APP_REGION) {
  console.warn('VITE_RUM_APP_REGION not set!')
}

export function initialiseRum() {
  if (
    !ENDPOINT ||
    !IDENTITY_POOL_ID ||
    !APP_ID ||
    !APP_VERSION ||
    !APP_REGION
  ) {
    console.log('Skipped initialising RUM')
    return
  }

  try {
    console.log('Initialising RUM')

    const config: AwsRumConfig = {
      enableRumClient: true,
      sessionSampleRate: 1,
      identityPoolId: IDENTITY_POOL_ID,
      endpoint: ENDPOINT,
      telemetries: ['errors', 'performance', 'http'],
      allowCookies: false,
      enableXRay: true,
    }

    return new AwsRum(APP_ID, APP_VERSION, APP_REGION, config)
  } catch (error) {
    // Ignore errors thrown during CloudWatch RUM web client initialization
    console.error('RUM failed to initialise', error)
  }

  return
}

export const rum = initialiseRum()

declare global {
  interface Window {
    rum?: AwsRum
  }
}

window.rum = rum
