import * as Icons from '@mui/icons-material'

import { When } from '@tk/frontend/primitives'
import { NavButton, NavSection } from '@tk/frontend/primitives/layout/Sidebar'

export const BloombergSidebar = () => {
  return (
    <When can="bloomberg-mappings.read" flag="bloombergMappings">
      <NavSection title="Bloomberg Mapping">
        <NavButton
          label="Record Mappings"
          path="/bloomberg/records-mappings"
          icon={
            <Icons.SettingsInputComponentRounded
              style={{ transform: 'rotate(90deg)' }}
            />
          }
        />
        <NavButton
          label="Tickers"
          path="/bloomberg/tickers"
          icon={<Icons.LabelRounded fontSize="small" />}
        />
        <NavButton
          label="Pricing Sources"
          path="/bloomberg/pricing-sources"
          icon={<Icons.LabelRounded fontSize="small" />}
        />
        <NavButton
          label="Entitlements"
          path="/bloomberg/entitlements"
          icon={<Icons.LabelRounded fontSize="small" />}
        />
        <NavButton
          label="Processes"
          path="/bloomberg/processes"
          icon={<Icons.LabelRounded fontSize="small" />}
        />
        <NavButton
          label="Rulesets"
          path="/bloomberg/rulesets"
          icon={<Icons.LabelRounded fontSize="small" />}
        />
      </NavSection>
    </When>
  )
}
