import * as Icons from '@mui/icons-material'
import { useNavigate } from '@tanstack/react-router'

import { trpc } from '@tk/frontend/api'
import { usePromiseNotification } from '@tk/frontend/primitives'
import { MenuItem } from '@tk/frontend/primitives/menu'
import { useFileUploader } from '@tk/frontend/primitives/useFileUploader'
import { telemetry } from '@tk/frontend/telemetry'

export type ImportButtonProps = {
  //
}

export function ImportButton({}: ImportButtonProps) {
  const prepare = trpc.records.import.prepare.useMutation()
  const create = trpc.records.import.start.useMutation({
    retry: false,
  })

  const notify = usePromiseNotification()
  const fileUploader = useFileUploader({
    types: 'csv',
    onFile: async (file, uploadFile) => {
      console.debug('[File Upload] Got File', file.name)

      async function process() {
        const storage = await prepare.mutateAsync()
        console.debug('[File Upload] Got URI for', storage.objectKey)

        await uploadFile(file, storage.uploadUri)
        console.debug('[File Upload] Uploaded File Successfully')

        const submitted = await create.mutateAsync({
          name: file.name,
          objectKey: storage.objectKey,
        })
        console.debug('[File Upload] Project Created Successfully')

        const taskId = submitted.taskId

        telemetry.capture('gtk/record-import/start', { importId: taskId })
        navigate({
          to: '/record-management/records/imports',
          search: { focus: taskId },
        })
      }

      await notify(process(), {
        progressMessage: 'Starting import process',
        failureMessage: 'Failed to import!',
        successMessage: 'Import now in progress',
      })
    },
  })

  const navigate = useNavigate()

  return (
    <MenuItem
      startIcon={<Icons.Upload />}
      onClick={fileUploader.start}
      disabled={fileUploader.working}
    >
      Import
    </MenuItem>
  )
}
