import { GridFilterItem, GridFilterModel } from '@mui/x-data-grid-premium'
import { createFileRoute } from '@tanstack/react-router'
import { isEmpty } from 'lodash'
import { z } from 'zod'

import { RecordMappingsCrud } from '@tk/frontend/app/Bloomberg'
import { FilterKey } from '@tk/frontend/app/Bloomberg/RecordMappings/filters-config'
import { MultiSelectFilterId } from '@tk/frontend/primitives/datagrid/columns'

const searchParams = z
  .object({
    'grid-filters': z.string().optional(),
    'initial-pricing-source-ids': z.number().optional(),
    'initial-ticker-ids': z.number().optional(),
    'initial-entitlement-ids': z.number().optional(),
    'initial-process-ids': z.number().optional(),
    'initial-ruleset-ids': z.number().optional(),
  })
  .optional()
  .transform((params) => {
    if (!params || isEmpty(params)) {
      return params
    }

    let gridFilters: GridFilterModel = { items: [] }

    if (params?.['grid-filters']) {
      try {
        gridFilters = JSON.parse(window.atob(params['grid-filters']))
      } catch {
        //Do nothing
      }
    }

    if (
      params['initial-pricing-source-ids'] &&
      params['initial-pricing-source-ids'] > 0
    ) {
      const defaultPricingSourceFilter: GridFilterItem = {
        id: -1,
        field: 'pricingSource' as FilterKey,
        operator: MultiSelectFilterId,
        value: [params['initial-pricing-source-ids']],
      }

      gridFilters.items = [
        ...gridFilters.items.filter(
          (it) => it.field !== defaultPricingSourceFilter.field
        ),
        defaultPricingSourceFilter,
      ]
      params['initial-pricing-source-ids'] = undefined
    }

    if (params['initial-ticker-ids'] && params['initial-ticker-ids'] > 0) {
      const defaultTickerFilter: GridFilterItem = {
        id: -1,
        field: 'ticker' as FilterKey,
        operator: MultiSelectFilterId,
        value: [params['initial-ticker-ids']],
      }

      gridFilters.items = [
        ...gridFilters.items.filter(
          (it) => it.field !== defaultTickerFilter.field
        ),
        defaultTickerFilter,
      ]
      params['initial-ticker-ids'] = undefined
    }

    if (
      params['initial-entitlement-ids'] &&
      params['initial-entitlement-ids'] > 0
    ) {
      const defaultEntitlementFilter: GridFilterItem = {
        id: -1,
        field: 'entitlement' as FilterKey,
        operator: MultiSelectFilterId,
        value: [params['initial-entitlement-ids']],
      }

      gridFilters.items = [
        ...gridFilters.items.filter(
          (it) => it.field !== defaultEntitlementFilter.field
        ),
        defaultEntitlementFilter,
      ]
      params['initial-entitlement-ids'] = undefined
    }

    if (params['initial-process-ids'] && params['initial-process-ids'] > 0) {
      const defaultProcessFilter: GridFilterItem = {
        id: -1,
        field: 'process' as FilterKey,
        operator: MultiSelectFilterId,
        value: [params['initial-process-ids']],
      }

      gridFilters.items = [
        ...gridFilters.items.filter(
          (it) => it.field !== defaultProcessFilter.field
        ),
        defaultProcessFilter,
      ]
      params['initial-process-ids'] = undefined
    }

    if (params['initial-ruleset-ids'] && params['initial-ruleset-ids'] > 0) {
      const defaultRulesetFilter: GridFilterItem = {
        id: -1,
        field: 'ruleset' as FilterKey,
        operator: MultiSelectFilterId,
        value: [params['initial-ruleset-ids']],
      }

      gridFilters.items = [
        ...gridFilters.items.filter(
          (it) => it.field !== defaultRulesetFilter.field
        ),
        defaultRulesetFilter,
      ]
      params['initial-ruleset-ids'] = undefined
    }

    if (gridFilters.items.length > 0) {
      params['grid-filters'] = window.btoa(JSON.stringify(gridFilters))
    }

    return params
  })

export const Route = createFileRoute('/bloomberg/records-mappings/')({
  component: RecordMappingsCrud,
  validateSearch: searchParams.parse,
})
