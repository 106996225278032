import { useCallback } from 'react'

import { useTanstackRouteEffect } from '@lib/web'
import {
  BloombergMappingEntity,
  forms,
  telemetry,
} from '@tk/frontend/telemetry'

export function useBloombergMappingTelemetry(
  crudEntity: BloombergMappingEntity
) {
  const trackTableShow = useCallback(() => {
    telemetry.capture('gtk/bloomberg-mapping/table/show', {
      crudEntity: crudEntity,
    })
  }, [crudEntity])

  const trackCreateStart = useCallback(() => {
    telemetry.capture('gtk/bloomberg-mapping/form/show', {
      crudEntity: crudEntity,
      mode: 'create',
    })
  }, [crudEntity])

  const trackCreateValidationError = useCallback(
    (
      dirty: any,
      errors: 'not-tracked' | forms.RhfErrorFields<any> = 'not-tracked'
    ) => {
      const { errorFields, errorMessages } = forms.getFieldErrors(errors)

      telemetry.capture('gtk/bloomberg-mapping/form/validation-failed', {
        crudEntity: crudEntity,
        mode: 'create',
        errorFields,
        errorMessages,
      })
    },
    [crudEntity]
  )

  const trackCreateSuccess = useCallback(
    ({ id, name }: Partial<{ id: number | string; name: string }>) => {
      telemetry.capture('gtk/bloomberg-mapping/form/save', {
        crudEntity: crudEntity,
        mode: 'create',
        entityId: id!,
        entityName: name!,
      })
    },
    [crudEntity]
  )

  const trackEditStart = useCallback(
    ({ id, name }: Partial<{ id: number | string; name: string }>) => {
      telemetry.capture('gtk/bloomberg-mapping/form/show', {
        crudEntity: crudEntity,
        mode: 'update',
        entityId: id!,
        entityName: name!,
      })
    },
    [crudEntity]
  )

  const trackEditSave = useCallback(
    (
      dirty: forms.RhfDirtyFields<any> | 'not-tracked',
      { id, name }: Partial<{ id: number | string; name: string }>
    ) => {
      telemetry.capture('gtk/bloomberg-mapping/form/save', {
        crudEntity: crudEntity,
        mode: 'update',
        dirtyFields: forms.getDirtyFields(dirty),
        entityId: id!,
        entityName: name!,
      })
    },
    [crudEntity]
  )

  return {
    trackTableShow,
    trackCreateStart,
    trackCreateValidationError,
    trackCreateSuccess,
    trackEditStart,
    trackEditSave,
  }
}

export function useBloombergMappingTableTelemetry(
  name: BloombergMappingEntity
) {
  const { trackTableShow } = useBloombergMappingTelemetry(name)

  useTanstackRouteEffect(() => {
    trackTableShow()
  })
}

export const useBloombergMappingCreateTelemetry = (
  name: BloombergMappingEntity
) => {
  const { trackCreateStart, trackCreateSuccess, trackCreateValidationError } =
    useBloombergMappingTelemetry(name)

  useTanstackRouteEffect(() => {
    trackCreateStart()
  })

  return {
    trackCreateSuccess,
    trackCreateValidationError,
  }
}
