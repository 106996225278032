import { useMemo } from 'react'

import { GridSortDirection } from '@mui/x-data-grid-premium'

import { useQueryParam } from '@tk/frontend/primitives/useQueryParam'

export type SortDirection = Extract<GridSortDirection, string>
export const sortDirKeys = ['asc', 'desc'] as SortDirection[]

export function useSortQueryParams<TSortField extends string>(
  defaultField: TSortField,
  defaultDirection: SortDirection = 'asc'
) {
  const [sort, setSort] = useQueryParam('sort')
  const [direction, setDirection] = useQueryParam<SortDirection>('direction', {
    defaultValue: defaultDirection,
  })

  return useMemo(() => {
    return {
      sort: (sort ?? defaultField) as TSortField,
      setSort,
      direction,
      setDirection,
    } as const
  }, [defaultField, direction, setDirection, setSort, sort])
}
