import { createFileRoute } from '@tanstack/react-router'
import { z } from 'zod'

import { InstrumentCreatePage } from '@tk/frontend/app/Multibrand/StubCrud'

const searchParams = z.object({
  'market-group': z.number().optional(),
})

export const Route = createFileRoute('/multibrand/stubs/instrument/create')({
  component: InstrumentCreatePage,
  validateSearch: searchParams.parse,
})
