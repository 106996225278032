import React, { useMemo } from 'react'

import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium'

import {
  FilterPanelButton,
  useFilterModel,
} from '@tk/frontend/primitives/datagrid'

import { FilterKey, FILTERS_CONFIG } from './filters-config'
import { deleteFilterTagFromModel } from './FilterTags'
import { RecordMappingColName } from './types'

interface RecordsMappingTableFilterRowProps {
  gridApiRef: React.MutableRefObject<GridApiPremium>
}

export function RecordMappingTableFilterRow({
  gridApiRef,
}: RecordsMappingTableFilterRowProps) {
  const filterModel = useFilterModel()

  const { model, setModel } = filterModel
  const tagItems = useMemo(() => {
    const items = model?.items ?? []

    return (
      items.flatMap((item) => {
        const value: unknown = item.value ?? []
        if (typeof value === 'string') {
          return {
            key: `${item.id}-${value}`,
            field: item.field as RecordMappingColName,
            value: value,
            handleDelete: () =>
              setModel(
                deleteFilterTagFromModel({
                  oldModel: filterModel.model,
                  item,
                  value,
                })
              ),
          }
        }

        if (!Array.isArray(value)) {
          return []
        }

        return value.map((itemValue: string) => {
          return {
            key: `${item.id}-${itemValue}`,
            field: item.field as RecordMappingColName,
            value: itemValue,
            handleDelete: () =>
              setModel(
                deleteFilterTagFromModel({
                  oldModel: filterModel.model,
                  item,
                  value: itemValue,
                })
              ),
          }
        })
      }) ?? []
    )
  }, [filterModel.model, model?.items, setModel])

  const count = tagItems.length

  return (
    <Stack data-filter-tag-count={count} direction="row" width="100%">
      <Box height="100%" alignItems="center" display="flex">
        <FilterPanelButton gridApiRef={gridApiRef} />
      </Box>

      <Stack
        direction="row"
        alignItems="center"
        flexWrap="wrap"
        useFlexGap
        gap="0.5rem"
      >
        {tagItems.map((item) => {
          const filterConfig = FILTERS_CONFIG[item.field as FilterKey]
          if (!filterConfig) {
            console.error(
              item.field,
              'was not found in FILTERS_CONFIG so cannot be presented'
            )
          }

          return (
            <filterConfig.FilterTag
              key={item.key}
              value={item.value}
              onDelete={item.handleDelete}
            />
          )
        })}
      </Stack>
    </Stack>
  )
}
