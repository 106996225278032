import * as Icons from '@mui/icons-material'

import { ButtonLink, useEntitlement } from '@tk/frontend/primitives'

export type EditRecordButtonProps = {
  selectedId: null | number | string
}

export function EditRecordButton({ selectedId }: EditRecordButtonProps) {
  const isEditMode = useEntitlement('records.manage')

  return (
    <ButtonLink
      to="/record-management/records/edit/$id"
      params={{ id: selectedId?.toString() ?? undefined }}
      variant="contained"
      disabled={!selectedId}
      startIcon={isEditMode ? <Icons.Edit /> : <Icons.Preview />}
      color="primary"
    >
      {isEditMode ? 'Edit' : 'View'}
    </ButtonLink>
  )
}
