import {
  GridFilterItem,
  GridFilterOperator,
  GridValidRowModel,
} from '@mui/x-data-grid-premium'

import { emulateDbFilter } from '@lib/utils'

export const WildcardOperatorName = 'wildcard'

export function getWildcardOperator<T extends GridValidRowModel>(
  getSearchValues: (row: T) => (string | undefined)[]
): GridFilterOperator<T> {
  return {
    label: 'Wildcard (%)',
    value: WildcardOperatorName,
    getApplyFilterFn(filterItem: GridFilterItem) {
      return (value, row, column): boolean => {
        const searchValues = getSearchValues(row)

        return searchValues.some((value) =>
          emulateDbFilter(filterItem.value, value)
        )
      }
    },
  }
}
