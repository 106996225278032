import { useMemo } from 'react'

import { Box, Typography } from '@mui/material'
import { DateTime } from 'luxon'

export type PairedDateCellProps = {
  label?: string
  dateOne: string | Date | null | undefined
  dateTwo: string | Date | null | undefined
}

const formatDate = (date: string | Date) => {
  const luxDate =
    typeof date === 'string'
      ? DateTime.fromISO(date)
      : DateTime.fromJSDate(date)

  const timePart = luxDate.toFormat('HH:mm')
  const datePart = luxDate.toFormat('dd MMM yyyy')

  return `${timePart} ${datePart}`
}

export function PairedDateCell({
  label,
  dateOne,
  dateTwo,
}: Readonly<PairedDateCellProps>) {
  const fmtDateOne = useMemo(
    () => (dateOne ? formatDate(dateOne) : '-'),
    [dateOne]
  )
  const fmtDateTwo = useMemo(
    () => (dateTwo ? formatDate(dateTwo) : '-'),
    [dateTwo]
  )

  if (!dateOne && !dateTwo) {
    return <></>
  }

  return (
    <Box sx={{ display: 'inline-block', textAlign: 'center' }}>
      {label && (
        <Typography
          variant="inherit"
          color="text.secondary"
          sx={{
            textAlign: 'left',
          }}
        >
          {label}
        </Typography>
      )}
      <Box>
        <Typography variant="body2" display="block">
          {fmtDateOne}
        </Typography>
        <Typography variant="body2" display="block">
          {fmtDateTwo}
        </Typography>
      </Box>
    </Box>
  )
}

export default PairedDateCell
