import { useState } from 'react'

import { Box, CircularProgress, Container, Typography } from '@mui/material'
import { createFileRoute, Navigate } from '@tanstack/react-router'

import { LoginCallback } from '@lib/okta-web'
import { ButtonLink, useTitle } from '@tk/frontend/primitives'

export const Route = createFileRoute('/login/callback')({
  component: Content,
})

function Content() {
  useTitle('Signing In')

  const search = Route.useSearch()

  // When the route is painted, LoginCallback will strip the search params.
  // If the user reloads then the'll get an infinite CircularProgress
  // So we detect this reload paint and redirect back through login again
  // Okta is really badly designed sometimes.
  const [isNakedRouteOnFirstPaint] = useState(() => !Object.keys(search).length)

  return (
    <Container>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        padding="2rem"
        gap="1rem"
      >
        <LoginCallback
          loadingElement={
            isNakedRouteOnFirstPaint ? (
              <Navigate to="/" />
            ) : (
              <CircularProgress />
            )
          }
          errorComponent={({ error }) => {
            return (
              <>
                <Typography variant="body1">{String(error)}</Typography>

                <ButtonLink to="/">Try Again</ButtonLink>
              </>
            )
          }}
        />
      </Box>
    </Container>
  )
}
