import * as Icons from '@mui/icons-material'

import { ButtonLink } from '@tk/frontend/primitives'

export function CreateRecordButton() {
  return (
    <ButtonLink
      to="/record-management/records/create"
      variant="contained"
      startIcon={<Icons.Add />}
      color="primary"
    >
      Create
    </ButtonLink>
  )
}
