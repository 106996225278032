import { useFormContext } from 'react-hook-form'

import { LinkedRecordsDto, RecordDto, RecordListingItemDto } from '@lib/pgs'
import { LinkedRecordsPopover } from '@tk/frontend/app/Records/LinkedRecordsPopover'

export type RenderLinkedCollectionProps = {
  linkText?: string
  recordOne: RecordListingItemDto | RecordDto
  recordTwo: RecordListingItemDto | RecordDto
  recordOneValidFromDate?: Date
  recordOneValidToDate?: Date
  recordTwoValidFromDate?: Date
  recordTwoValidToDate?: Date
  recordOneIsNew?: boolean
  preview: 'recordOne' | 'recordTwo'
}

export function RenderLinkedCollection({
  linkText,
  recordOne,
  recordTwo,
  recordOneValidFromDate,
  recordOneValidToDate,
  recordTwoValidFromDate,
  recordTwoValidToDate,
  recordOneIsNew,
  preview = 'recordOne',
}: Readonly<RenderLinkedCollectionProps>) {
  const { watch } = useFormContext()
  const recordOneStatus = watch('recordOneStatus')
  const recordTwoStatus = watch('recordTwoStatus')

  // Record One Preview
  if (preview === 'recordOne') {
    if (recordOneIsNew) {
      return (
        <LinkedRecordsPopover
          linkedRecords={(recordOne.linkedRecords ?? []).map(LinkedRecordsDto)}
          record={{
            ...(recordOne as RecordDto),
            validFromDate: recordOneValidFromDate,
            validToDate: recordOneValidToDate,
            status: recordOneStatus,
          }}
          secondRecord={
            recordTwo
              ? {
                  ...recordTwo,
                  validFromDate: recordTwoValidFromDate,
                  validToDate: recordTwoValidToDate,
                  status: recordTwoStatus,
                }
              : undefined
          }
          linkText={linkText}
        />
      )
    } else {
      return (
        <LinkedRecordsPopover
          linkedRecords={recordOne?.linkedRecords ?? []}
          record={undefined}
          secondRecord={undefined}
          linkText={linkText}
        />
      )
    }
  }

  // Record Two Preview
  if (preview === 'recordTwo') {
    if (!recordOneIsNew) {
      return (
        <LinkedRecordsPopover
          linkedRecords={(recordTwo.linkedRecords ?? []).map(LinkedRecordsDto)}
          record={{
            ...(recordTwo as RecordDto),
            validFromDate: recordTwoValidFromDate,
            validToDate: recordTwoValidToDate,
            status: recordTwoStatus,
          }}
          secondRecord={
            recordOne
              ? {
                  ...recordOne,
                  validFromDate: recordOneValidFromDate,
                  validToDate: recordOneValidToDate,
                  status: recordOneStatus,
                }
              : undefined
          }
          linkText={linkText}
        />
      )
    } else {
      return (
        <LinkedRecordsPopover
          linkedRecords={recordTwo?.linkedRecords ?? []}
          record={undefined}
          secondRecord={undefined}
          linkText={linkText}
        />
      )
    }
  }

  return null
}
