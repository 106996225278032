import * as Icons from '@mui/icons-material'
import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { DateTime } from 'luxon'

import {
  AppLink,
  InfoPopover,
  SuspenseBoundaryLocal,
} from '@tk/frontend/primitives'

import { RECORD_STATUS_OPTIONS } from './common/crud/constants'
import { ParentChain } from './types'

export type CollectingInChainPopoverProps = {
  chains: ParentChain[]
}

function isRecent(date: Date) {
  return DateTime.fromJSDate(date).diffNow('days').days >= -7
}

export function CollectingInChainPopover({
  chains,
}: CollectingInChainPopoverProps) {
  const collectedThisWeek = chains.filter(
    (c) => c.status === 'COLLECT' && isRecent(c.lastChecked)
  )

  return (
    <InfoPopover
      colour="inherit"
      contentContainer={<Stack direction="column" padding="0 0.5rem" />}
      icon={
        <Icons.Launch
          fontSize="inherit"
          color="primary"
          style={{ marginLeft: '0.15rem' }}
        />
      }
      content={
        <SuspenseBoundaryLocal width="20rem" height={`${chains.length * 2}rem`}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell width={300}>Name</TableCell>

                <TableCell width={100}>Status</TableCell>

                <TableCell width={120}>
                  <InfoPopover
                    iconPosition="prefix"
                    colour="dark"
                    content="The last time the Record was observed on this Chain. Records might appear on chains permanently, by mistake, or simply get removed eventually"
                  >
                    <p>Last Seen</p>
                  </InfoPopover>
                </TableCell>

                <TableCell colSpan={2} align="center">
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {chains
                .sort((a, b) => {
                  const dateA = new Date(a.lastChecked).valueOf()
                  const dateB = new Date(b.lastChecked).valueOf()

                  return dateB - dateA
                })
                .map((chain) => {
                  return (
                    <TableRow
                      key={chain.id}
                      sx={{ opacity: isRecent(chain.lastChecked) ? 1 : 0.5 }}
                    >
                      <TableCell>{chain.name}</TableCell>

                      <TableCell>
                        <Typography fontWeight="bold" fontSize="inherit">
                          {RECORD_STATUS_OPTIONS.find(
                            (option) => option.value === chain.status
                          )?.label ?? chain.status}
                        </Typography>
                      </TableCell>

                      <TableCell>
                        {DateTime.fromJSDate(chain.lastChecked).toFormat(
                          'HH:mm dd LLL yyyy'
                        )}
                      </TableCell>

                      <TableCell>
                        <AppLink
                          to="/record-management/records"
                          search={{ 'initial-chain': chain.id }}
                          target="_blank"
                        >
                          List Items
                          <Icons.Launch
                            fontSize="inherit"
                            style={{
                              marginLeft: '0.15rem',
                              marginBottom: '-2px',
                            }}
                          />
                        </AppLink>
                      </TableCell>

                      <TableCell>
                        <AppLink
                          to="/record-management/records/edit/$id"
                          params={{ id: chain.id.toString() }}
                          target="_blank"
                        >
                          Open Record
                          <Icons.Launch
                            fontSize="inherit"
                            style={{
                              marginLeft: '0.15rem',
                              marginBottom: '-2px',
                            }}
                          />
                        </AppLink>
                      </TableCell>
                    </TableRow>
                  )
                })}
            </TableBody>

            <TableFooter>
              <TableRow>
                <TableCell colSpan={3}>
                  <Typography variant="subtitle2">
                    <ul>
                      <li>
                        A record may collect via its own status, or via a Parent
                        Chain's status
                      </li>
                      <li>
                        We track Chain Items automatically, but only know the
                        last date we saw a link on RTDS
                      </li>
                      <li>
                        Anything last seen over 7 days ago will be greyed out
                        here, but might still be seen again in the future
                      </li>
                    </ul>
                  </Typography>
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </SuspenseBoundaryLocal>
      }
    >
      {collectedThisWeek.length > 0 && (
        <Typography variant="body2">
          Also "Collect" on <strong>{collectedThisWeek.length}</strong> Chain
          {collectedThisWeek.length === 1 ? '' : 's'}
        </Typography>
      )}

      {collectedThisWeek.length === 0 && (
        <Typography variant="body2">
          Also seen on <strong>{chains.length}</strong> Chain
          {chains.length === 1 ? '' : 's'}
        </Typography>
      )}
    </InfoPopover>
  )
}
