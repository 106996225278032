import { useMemo } from 'react'

import { Paper, Stack, Typography } from '@mui/material'
import { DataGridPremium } from '@mui/x-data-grid-premium'
import { useParams } from '@tanstack/react-router'

import { trpc, useUtils } from '@tk/frontend/api'
import {
  defaultProps,
  GridEnrichedColDef,
  usePaginationQueryParams,
} from '@tk/frontend/primitives/datagrid'
import {
  getDiffCell as getCoreDiffCell,
  getRowId,
} from '@tk/frontend/primitives/datagrid/diff'
import { PageContentLayout, PaperCell } from '@tk/frontend/primitives/layout'
import {
  ApplyImportButton,
  ImportPreviewRowFilter,
  ImportPreviewSummary,
  ImportStateColour,
  PreviewNotFound,
  useImportHasAnyError,
  usePreviewRowFilterParam,
  usePreviewTelemetry,
  useSimpleImportProvider,
} from '@tk/frontend/primitives/simple-import'

import { ImportPreviewDto, PreviewDto } from './types'

const getDiffCell = getCoreDiffCell<PreviewDto>

export function TickersImportPreview() {
  const utils = useUtils()

  const simpleImportProvider = useSimpleImportProvider({
    route: trpc.bloomberg.ticker.import,
    utils: utils.bloomberg.ticker.import,
    previewUriTemplate: '/bloomberg/tickers/import/$taskId',
  })

  return (
    <simpleImportProvider.Provider {...simpleImportProvider.providerProps}>
      <TickersImportPreviewContent />
    </simpleImportProvider.Provider>
  )
}

function TickersImportPreviewContent() {
  const { taskId } = useParams({
    from: '/bloomberg/tickers/import/$taskId',
  })

  const [rowFilter] = usePreviewRowFilterParam()
  const [pagination, onPaginationChange] = usePaginationQueryParams({
    resetPageFor: [rowFilter],
    initialPageSize: 100,
  })

  const [preview, previewQuery] =
    trpc.bloomberg.ticker.import.preview.useSuspenseQuery({
      taskId: taskId,
      page: pagination.page,
      size: pagination.pageSize,
      invalidOnly: rowFilter === 'errors',
    })

  usePreviewTelemetry('bloomberg/tickers', taskId)

  const numErrors = useImportHasAnyError(taskId)

  const columns = useMemo<GridEnrichedColDef<ImportPreviewDto>[]>(() => {
    return [
      {
        type: 'string',
        field: 'rowNumber',
        headerName: 'Row Number',
        filterable: false,
        sortable: false,
        aggregable: false,
      },
      {
        type: 'string',
        field: 'errorMessage',
        headerName: 'Summary',
        width: 200,
        filterable: false,
        sortable: false,
        aggregable: false,
        display: 'flex',
        renderCell(params) {
          if (params.row.errorMessage || !params.row.isValid) {
            return (
              <Typography fontWeight="bold" color={ImportStateColour['FAILED']}>
                {params.row.errorMessage ?? 'Error'}
              </Typography>
            )
          }

          if (params.row.currentVersion) {
            return (
              <Typography
                fontWeight="bold"
                color={(theme) => theme.palette.primary.main}
              >
                UPDATE
              </Typography>
            )
          } else {
            return (
              <Typography
                fontWeight="bold"
                color={(theme) => theme.palette.success.main}
              >
                CREATE
              </Typography>
            )
          }
        },
      },
      getDiffCell('name', 'Name', { width: 300 }),
      getDiffCell('description', 'Description', { width: 400 }),
      getDiffCell('objectId', 'Object ID', { width: 300 }),
    ]
  }, [])

  return preview.status === 'success' ? (
    <PageContentLayout
      title="Bloomberg Tickers Import"
      controls={
        <ApplyImportButton
          entity="bloomberg/tickers"
          taskId={taskId}
          disabled={numErrors > 0}
        />
      }
      childrenContainer={<></>}
      maxContentWidth={false}
    >
      <Stack display="flex" height="100%">
        <Stack direction="row">
          <ImportPreviewSummary taskId={taskId} />

          <PaperCell>
            <ImportPreviewRowFilter />
          </PaperCell>
        </Stack>

        <Paper
          sx={{ display: 'flex', flex: '1 1 0', minHeight: 0, minWidth: 0 }}
        >
          <DataGridPremium
            {...defaultProps}
            pagination
            paginationMode="server"
            paginationModel={pagination}
            onPaginationModelChange={onPaginationChange}
            pageSizeOptions={[100]}
            rows={preview.response.content ?? []}
            rowCount={preview.response.totalElements ?? 0}
            columns={columns}
            getRowId={getRowId}
            loading={previewQuery.isRefetching}
          />
        </Paper>
      </Stack>
    </PageContentLayout>
  ) : (
    <PreviewNotFound label="Tickers" path="/bloomberg/tickers" />
  )
}
