import { useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'

import {
  useRouterState,
  useLocation as useTanstackLocation,
} from '@tanstack/react-router'

/**
 * Uses react-router to determine if an effect should fire again using the pathname.
 * Internally stabilises the effect callback, but doesn't freeze the reference!
 *
 * Useful where we need to fire some event once per page route, where the route-id is considered but query-params are not.
 */
export function useRouteEffect(effect: () => void | (() => void)) {
  const { pathname } = useLocation()
  const effectedPath = useRef<string>('')

  const effectRef = useRef(effect)
  useEffect(() => {
    effectRef.current = effect
  }, [effect])

  useEffect(() => {
    if (effectedPath.current !== pathname) {
      effectedPath.current = pathname
      return effectRef.current()
    }
  }, [pathname])
}

// TODO: Can probably be replaced by the beforeLoad function within the route definition
export function useTanstackRouteEffect(effect: () => void | (() => void)) {
  const { pathname } = useTanstackLocation()
  const { isLoading } = useRouterState()

  const effectedPath = useRef<string>('')

  const effectRef = useRef(effect)
  useEffect(() => {
    effectRef.current = effect
  }, [effect])

  useEffect(() => {
    if (effectedPath.current !== pathname && !isLoading) {
      effectedPath.current = pathname
      return effectRef.current()
    }
  }, [isLoading, pathname])
}
