import OktaAuth, { toRelativeUrl } from '@okta/okta-auth-js'
import NiceModal from '@parameta/nice-modal-react'
import {
  createRootRouteWithContext,
  FileRoutesByPath,
} from '@tanstack/react-router'

import { AuthedLayout } from '@tk/frontend/app/app'
import { Layout } from '@tk/frontend/primitives/layout'

interface RouterContext {
  auth: OktaAuth
}
const LOGIN_REDIRECT_LANDING_PATHNAME: keyof FileRoutesByPath =
  '/login/callback'

export const Route = createRootRouteWithContext<RouterContext>()({
  component: RootComponent,
  beforeLoad: async (ctx) => {
    const isLoginCallback = ctx.location.pathname.startsWith(
      LOGIN_REDIRECT_LANDING_PATHNAME
    )

    const oktaAuth = ctx.context.auth
    const authState = oktaAuth.authStateManager.getAuthState()

    if (!authState) {
      return
    }

    if (!authState?.isAuthenticated && !isLoginCallback) {
      const originalUri = toRelativeUrl(
        window.location.href,
        window.location.origin
      )
      oktaAuth.setOriginalUri(originalUri)
      await oktaAuth.signInWithRedirect()
    }
  },
  loader(ctx) {
    const isLoginRedirectLandingPage =
      ctx.location.pathname === LOGIN_REDIRECT_LANDING_PATHNAME

    return {
      isLoginRedirectLandingPage,
    }
  },
})

function RootComponent() {
  const isLoginRedirectLandingPage = Route.useLoaderData({
    select(match) {
      return match.isLoginRedirectLandingPage
    },
  })
  if (isLoginRedirectLandingPage) {
    return <Layout />
  }

  return (
    <NiceModal.Provider>
      <AuthedLayout />
    </NiceModal.Provider>
  )
}
