import { ReactNode } from 'react'

import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js'
import { Security } from '@okta/okta-react'
import { useNavigate } from '@tanstack/react-router'

import { environment } from './environment'

interface AppSecurityProps {
  children: ReactNode
}

/**
 * Gets a central AppSecurity abstraction with an instance of OktaAuth preconfigured
 */
export const getAppSecurity = () => {
  const oktaAuth = new OktaAuth({
    clientId: environment.auth.clientId,
    issuer: environment.auth.issuer,
    redirectUri: window.location.origin + '/login/callback',
    scopes: ['profile', 'openid', 'email', 'offline_access'],
    //
    // https://github.com/okta/okta-auth-js/blob/master/docs/autoRenew-notice.md
    services: {
      autoRemove: false,
      autoRenew: false,
    },
    tokenManager: {
      autoRenew: true,
      expireEarlySeconds: 61,
    },
  })

  const AppSecurity = ({ children }: AppSecurityProps) => {
    const navigate = useNavigate()

    return (
      <Security
        oktaAuth={oktaAuth}
        restoreOriginalUri={(client, uri) =>
          navigate({
            to: toRelativeUrl(uri || '/', window.location.origin),
            replace: true,
          })
        }
      >
        {children}
      </Security>
    )
  }

  return { AppSecurity, oktaAuth }
}
