import { createFileRoute } from '@tanstack/react-router'

import { RouterWhen } from '@tk/frontend/primitives'

export const Route = createFileRoute('/fids')({
  component: Surface,
})

function Surface() {
  return <RouterWhen flag="fids" />
}
