import { forwardRef, ReactNode } from 'react'

import MuiLink, { LinkProps as MuiLinkProps } from '@mui/material/Link'
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
  useLinkProps,
  useNavigate,
} from '@tanstack/react-router'

type BaseProps = RouterLinkProps & MuiLinkProps

interface Props extends BaseProps {
  children?: ReactNode
}

/**
 * Custom Link component that combines Mui Link and tanstack router Link.
 * It accepts the same props as tanstack router's Link component.
 *
 * @link https://reactrouter.com/en/main/components/link
 */
export const AppLink = forwardRef<HTMLAnchorElement, Props>(
  ({ to, params, search, ...props }, ref) => {
    const navigate = useNavigate()
    const { href } = useLinkProps({ to, params, search })

    return (
      <MuiLink
        component={RouterLink}
        onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
          // This is necessary because when used inside a table cell, the table cell hijacks the click handler
          e.preventDefault()
          e.stopPropagation()

          if (props.target) {
            window.open(href, props.target)
          } else {
            navigate({ to, params, search })
          }
        }}
        ref={ref}
        {...props}
        sx={{
          whiteSpace: 'nowrap',
        }}
      />
    )
  }
)
