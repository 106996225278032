import { useCallback } from 'react'
import { Path, useFormContext } from 'react-hook-form'

import { formatTableValue } from '@tk/frontend/primitives/MultibrandAttributeType'

import { MultibrandAttributeType, RrnStructureFormData } from './types'
import { useAttributeValues } from './useAttributeValues'

export function useRrnAttributeChangeHandler() {
  const { setValue } = useFormContext()
  const values = useAttributeValues()

  const handleAttributeChange = useCallback(
    (
      fieldName: Path<RrnStructureFormData> | undefined,
      value: MultibrandAttributeType
    ) => {
      if (!fieldName) {
        return
      }

      const fieldIndex = values.findIndex(([field]) => field === fieldName)

      if (fieldIndex === -1) {
        return
      }

      // Recalculate and set the name
      const attributeValues = values
        .map(([name, fieldValue]) => {
          if (name === fieldName) {
            return formatTableValue(value)
          }

          return formatTableValue(fieldValue as MultibrandAttributeType)
        })
        .filter((fieldValue, index) =>
          value ? !!fieldValue : index < fieldIndex
        )
        .join(' - ')

      setValue('name', attributeValues)

      // Clear values from later fields if the value was changed to an empty string
      if (!value) {
        for (let i = fieldIndex + 1; i < values.length; i++) {
          const [key] = values[i]

          setValue(key, null)
        }
      }
    },
    [setValue, values]
  )

  return handleAttributeChange
}
