import { useCallback } from 'react'

import { useNavigate, useSearch } from '@tanstack/react-router'

type Opts<T, TEncoded = any> = {
  defaultValue?: T
  decode?: (value: TEncoded) => T
  encode?: (value: T | undefined) => TEncoded
}

export const useQueryParam = <T>(paramName: string, opts?: Opts<T>) => {
  const { decode, defaultValue, encode } = opts ?? {}
  const params = useSearch({ strict: false }) ?? {}
  const _value = (params?.[paramName as keyof typeof params] ??
    defaultValue) as T | undefined
  const value = decode ? decode(_value) : _value
  const navigate = useNavigate()

  const setValue = useCallback(
    (newValue: T | undefined) => {
      navigate({
        to: '.',
        search: (oldParams: object | undefined) => {
          const encodedValue = encode ? encode(newValue) : newValue
          const newParams = { ...oldParams, [paramName]: encodedValue }

          if (encodedValue === undefined) {
            delete newParams[paramName]
          }

          return newParams
        },
        replace: true,
      })
    },
    [encode, navigate, paramName]
  )

  return [value, setValue] as const
}
