import React from 'react'

import * as Icons from '@mui/icons-material'

import { When } from '@tk/frontend/primitives'
import { MenuButton } from '@tk/frontend/primitives/menu'

import { DownloadRelinkTemplateButton } from './LinkRecordsBulk/DownloadRelinkTemplateButton'
import { RelinkButton } from './LinkRecordsBulk/RelinkButton'
import { RelinkListButton } from './LinkRecordsBulk/RelinkListButton'

export function RelinkRecordsMenu() {
  return (
    <MenuButton label="Link Records" icon={<Icons.Link />}>
      <DownloadRelinkTemplateButton />
      <When can="records.manage">
        <RelinkListButton />
        <RelinkButton />
      </When>
    </MenuButton>
  )
}
