import React, { useMemo, useState } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

import {
  Box,
  Button,
  Checkbox,
  Divider,
  Grid,
  Stack,
  Typography,
} from '@mui/material'

import {
  LinkRecordsRequestDto,
  RecordDto,
  RecordListingItemDto,
} from '@lib/pgs'
import { trpc } from '@tk/frontend/api'
import { RecordEditDto } from '@tk/frontend/app/Records/common/crud'
import { RECORD_STATUS_OPTIONS } from '@tk/frontend/app/Records/common/crud/constants'
import {
  getFormComponents,
  usePromiseNotification,
} from '@tk/frontend/primitives'
import {
  PairedDateCell,
  TwoLineTextCell,
} from '@tk/frontend/primitives/datagrid/cells'
import { DatePickerField } from '@tk/frontend/primitives/forms/DateTimePicker'
import { FieldInfo } from '@tk/frontend/primitives/forms/FieldInfo'
import { SimpleRecordSearch } from '@tk/frontend/primitives/forms/SimpleRecordSearch'

import { RenderLinkedCollection } from './RenderLinkedCollection'
import { useValidDates } from './useValidDates'

type Props = {
  onFinished?: () => void
}
const Form = getFormComponents<LinkRecordsRequestDto>()

export function LinkRecordsForm({ onFinished }: Readonly<Props>) {
  const progress = usePromiseNotification()
  const [selectedRecord, setSelectedRecord] =
    useState<RecordListingItemDto | null>(null)

  const record = useWatch<RecordDto>()
  const editForm = useFormContext<RecordEditDto>()

  const form = Form.useForm({
    defaultValues: {
      recordOneId: record.id,
      recordOneStatus: record.status,
      recordTwoId: 0,
      recordTwoStatus: 'COLLECT',
      cutoverDate: undefined,
      recordOneIsNew: true,
      clearExistingValidDates: false,
    },
  })

  const { setValue, watch, handleSubmit: _handleSubmit } = form

  const linkMutation = trpc.records.linkRecords.useMutation()

  const [filterError, setFilterError] = useState<string | undefined>(undefined)

  const handleRecordSelect = (selected: RecordListingItemDto) => {
    if (record.linkedRecords?.some((linked) => linked.id === selected.id)) {
      setFilterError(
        'This record is already linked. Please select a different record.'
      )
    } else if (selected.id === record.id) {
      setFilterError('Cannot link a record to itself.')
    } else {
      setFilterError(undefined)
      setSelectedRecord(selected)
      setValue('recordTwoId', selected.id, { shouldDirty: true })
      setValue('recordTwoStatus', selected.status || 'COLLECT', {
        shouldDirty: true,
      })
    }
  }

  const clearSelectedRecord = () => {
    setSelectedRecord(null)
    setValue('cutoverDate', undefined, { shouldDirty: true })
    setValue('recordTwoId', 0, { shouldDirty: true })
    setValue('recordTwoStatus', 'COLLECT', { shouldDirty: true })
  }
  const cutoverDate = watch('cutoverDate')
  const recordOneIsNew = watch('recordOneIsNew')
  const recordOneStatus = watch('recordOneStatus')

  const {
    recordOneValidFromDate,
    recordOneValidToDate,
    recordTwoValidFromDate,
    recordTwoValidToDate,
  } = useValidDates({
    record: record as RecordDto,
    selectedRecord,
    cutoverDate: cutoverDate ?? undefined,
    recordOneIsNew: recordOneIsNew ?? true,
    clearExistingValidDates: watch('clearExistingValidDates') ?? false,
  })

  const handleSubmit = useMemo(() => {
    return _handleSubmit(async (values: LinkRecordsRequestDto) => {
      const payload: LinkRecordsRequestDto = {
        ...values,
        cutoverDate: values.cutoverDate || undefined,
      }

      try {
        const promise = linkMutation.mutateAsync(payload)
        await progress(promise, {
          progressMessage: 'Linking records...',
          successMessage: 'Records linked successfully!',
          failureMessage: 'Failed to link records. Please try again.',
        })

        editForm.setValue('status', recordOneStatus, { shouldDirty: true })
        editForm.setValue('validFromDate', recordOneValidFromDate, {
          shouldDirty: true,
        })
        editForm.setValue('validToDate', recordOneValidToDate, {
          shouldDirty: true,
        })

        onFinished?.()
      } catch (error) {
        console.error('Failed to link records:', error)
      }
    })
  }, [
    _handleSubmit,
    linkMutation,
    progress,
    onFinished,
    editForm,
    recordOneStatus,
    recordOneValidFromDate,
    recordOneValidToDate,
  ])

  return (
    <Form.Provider {...form}>
      <Stack
        width="100%"
        component="form"
        onSubmit={handleSubmit}
        maxWidth="2400px"
        gap={4}
        padding={0}
        sx={{ margin: '0 auto' }}
      >
        <Grid container spacing={2} sx={{ padding: 2 }}>
          {/* Top Row */}
          <Grid container item xs={12} spacing={2}>
            {/* Left Column */}
            <Grid item xs={5.8}>
              <Grid container spacing={2}>
                {/* First Row: Record One */}
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    height: '60px',
                    padding: 0,
                    margin: 0,
                  }}
                >
                  <TwoLineTextCell
                    label="Record One:"
                    line1={record.name}
                    line2={
                      record.description ?? <Typography>&nbsp;</Typography>
                    }
                  />
                </Grid>

                {/* Second Row: Source and Valid Dates */}
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TwoLineTextCell
                        label="Source:"
                        line1={record.backbone?.name}
                        line2={record.source?.name}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      {recordOneValidFromDate ||
                      recordOneValidToDate ||
                      cutoverDate ? (
                        <PairedDateCell
                          label="Valid Dates:"
                          dateOne={recordOneValidFromDate}
                          dateTwo={recordOneValidToDate}
                        />
                      ) : (
                        <Typography variant="body2" color="text.secondary">
                          No valid dates set
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </Grid>

                {/* Third Row: Status */}
                <Grid item xs={12} marginY={2}>
                  <Form.Field
                    label="Status:"
                    name="recordOneStatus"
                    input={
                      <Form.SelectField
                        rules={Form.rules.required}
                        options={RECORD_STATUS_OPTIONS}
                      />
                    }
                  />
                </Grid>

                {/* Fourth Row: Collection Preview and New Record */}
                <Grid item xs={12}>
                  <Grid
                    container
                    spacing={2}
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Grid item xs="auto">
                      {'Linked Collection Preview:'}
                      <RenderLinkedCollection
                        recordOne={record as RecordDto}
                        recordTwo={selectedRecord as RecordListingItemDto}
                        recordOneValidFromDate={recordOneValidFromDate}
                        recordOneValidToDate={recordOneValidToDate}
                        recordTwoValidFromDate={recordTwoValidFromDate}
                        recordTwoValidToDate={recordTwoValidToDate}
                        recordOneIsNew={recordOneIsNew}
                        preview="recordOne"
                      />
                    </Grid>
                    <Grid
                      item
                      xs="auto"
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        marginLeft: 'auto',
                      }}
                    >
                      <Form.Field
                        label="New Record"
                        name="recordOneIsNew"
                        info={
                          <FieldInfo
                            details={
                              <>
                                <p>
                                  <strong>
                                    The "New Record" will remain in its current
                                    collection
                                  </strong>{' '}
                                  The other Record will be linked into this
                                  collection.
                                </p>

                                <p>
                                  Click on Collection Preview to see how the new
                                  collection will look.
                                </p>
                              </>
                            }
                          />
                        }
                        input={
                          <Checkbox
                            disableRipple
                            sx={{ padding: 1 }}
                            checked={watch('recordOneIsNew')}
                            onChange={(e) =>
                              setValue('recordOneIsNew', e.target.checked)
                            }
                          />
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {/* Divider */}
            <Grid item>
              <Divider
                orientation="vertical"
                sx={{
                  height: '100%',
                  borderWidth: '1px',
                  borderColor: '#ddd',
                  marginX: 0.5,
                }}
              />
            </Grid>

            {/* Right Column */}
            <Grid
              item
              xs={5.8}
              sx={{
                paddingLeft: 4,
              }}
              width="100%"
            >
              <Grid container spacing={2} width="100%">
                {/* Second Row: Record Two and Clear Selected Button */}
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: 'flex',
                    alignItems: 'flex-start', // Align items to the top
                    justifyContent: 'space-between',
                    height: '60px',
                    padding: 0,
                    margin: 0,
                    width: '100%',
                  }}
                >
                  {/* Second Record Name and Description */}
                  {selectedRecord ? (
                    <TwoLineTextCell
                      label="Record Two:"
                      line1={selectedRecord.name}
                      line2={
                        selectedRecord.description ?? (
                          <Typography>&nbsp;</Typography>
                        )
                      }
                    />
                  ) : (
                    <SimpleRecordSearch
                      onSelect={handleRecordSelect}
                      errorMessage={filterError}
                      placeholder="Select Record to link to..."
                      label="Record Two:"
                    />
                  )}

                  {/* Clear Selected Record Button */}
                  {selectedRecord && (
                    <Button
                      variant="outlined"
                      color="secondary"
                      sx={{
                        minWidth: '50px',
                        marginLeft: 'auto',
                      }}
                      onClick={clearSelectedRecord}
                      disabled={
                        !selectedRecord ||
                        !form.formState.isValid ||
                        linkMutation.isPending
                      }
                    >
                      Clear
                    </Button>
                  )}
                </Grid>

                {/* Second Row: Source and Valid Dates */}
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      {selectedRecord ? (
                        <TwoLineTextCell
                          label="Source:"
                          line1={selectedRecord.backbone?.name || 'Unknown'}
                          line2={selectedRecord.source?.name || 'Unknown'}
                        />
                      ) : (
                        <Typography variant="body2" color="textSecondary">
                          No record selected
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={6}>
                      {selectedRecord && (
                        <>
                          {recordOneValidFromDate ||
                          recordOneValidToDate ||
                          cutoverDate ? (
                            <PairedDateCell
                              label="Valid Dates:"
                              dateOne={recordTwoValidFromDate}
                              dateTwo={recordTwoValidToDate}
                            />
                          ) : (
                            <Typography variant="body2" color="textSecondary">
                              No valid dates set
                            </Typography>
                          )}
                        </>
                      )}
                    </Grid>
                  </Grid>
                </Grid>

                {/* Third Row: Status */}
                <Grid item xs={12} marginY={2}>
                  {selectedRecord && (
                    <Form.Field
                      label="Status:"
                      name="recordTwoStatus"
                      input={
                        <Form.SelectField
                          rules={Form.rules.required}
                          options={RECORD_STATUS_OPTIONS}
                        />
                      }
                    />
                  )}
                </Grid>

                {/* Fourth Row: Collection Preview and New Record */}
                <Grid item xs={12}>
                  {selectedRecord && (
                    <Grid
                      container
                      spacing={2}
                      alignItems="center"
                      justifyContent="space-between"
                      marginBottom={2}
                    >
                      <Grid item xs="auto">
                        {'Linked Collection Preview:'}
                        <RenderLinkedCollection
                          recordOne={record as RecordDto}
                          recordTwo={selectedRecord}
                          recordOneValidFromDate={recordOneValidFromDate}
                          recordOneValidToDate={recordOneValidToDate}
                          recordTwoValidFromDate={recordTwoValidFromDate}
                          recordTwoValidToDate={recordTwoValidToDate}
                          recordOneIsNew={recordOneIsNew}
                          preview="recordTwo"
                        />
                      </Grid>
                      <Grid
                        item
                        xs="auto"
                        sx={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          alignItems: 'center',
                        }}
                      >
                        <Form.Field
                          label="New Record"
                          name="recordOneIsNew"
                          input={
                            <Checkbox
                              disableRipple
                              sx={{ padding: 1 }}
                              checked={!watch('recordOneIsNew')}
                              onChange={(e) =>
                                setValue('recordOneIsNew', !e.target.checked)
                              }
                            />
                          }
                        />
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Stack
            gap={2}
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              marginX: 2,
              marginY: 2,
              borderTop: '1px solid #ddd',
            }}
          >
            {selectedRecord && (
              <Stack
                direction="row"
                spacing={2}
                alignItems="center"
                justifyContent="space-between"
                sx={{
                  width: '100%',
                  marginX: 1,
                  marginBottom: 2,
                  borderTop: '1px solid #ddd',
                  paddingTop: 2,
                }}
              >
                {/* Shortened Cutover Date Field */}
                <Box sx={{ flex: '0 1 500px' }}>
                  {/* Fixed width */}
                  <Form.Field
                    label="Cutover Date:"
                    name="cutoverDate"
                    info={
                      <FieldInfo
                        title="Cutover Date (UTC):"
                        details={
                          <p>
                            Sets the Valid To Date for the original record and
                            the Valid From Date for the new record.
                          </p>
                        }
                      />
                    }
                    input={<DatePickerField name="cutoverDate" />}
                  />
                </Box>

                {/* Widened Clear Existing Valid Dates Checkbox */}
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',
                  }}
                >
                  <Form.Field
                    label="Clear Existing Valid Dates"
                    name="clearExistingValidDates"
                    info={
                      <FieldInfo
                        details={
                          <p>
                            Selecting this will clear the existing valid dates
                            for both records.
                          </p>
                        }
                      />
                    }
                    input={
                      <Checkbox
                        disableRipple
                        sx={{ padding: 1, marginLeft: 1 }}
                        checked={watch('clearExistingValidDates')}
                        onChange={(e) =>
                          setValue(
                            'clearExistingValidDates',
                            e.target.checked,
                            {
                              shouldDirty: true,
                            }
                          )
                        }
                      />
                    }
                  />
                </Box>
              </Stack>
            )}
          </Stack>
          {/* Button Row */}
          <Grid item xs={12}>
            <Grid
              container
              spacing={4}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={{ minWidth: '200px' }}
                  disabled={
                    !selectedRecord ||
                    !form.formState.isValid ||
                    linkMutation.isPending
                  }
                >
                  Link Records
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Stack>
    </Form.Provider>
  )
}
