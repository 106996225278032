import React, { ReactElement, ReactNode } from 'react'
import {
  FieldError,
  FieldValues,
  get,
  Path,
  useFormState,
} from 'react-hook-form'

import * as Icons from '@mui/icons-material'
import { Box, FormLabel, Stack, TextFieldProps } from '@mui/material'

import { InfoPopover } from '@tk/frontend/primitives/InfoPopover'

import { useFormContext } from './FormConfigContext'
import { Cell, RowStack } from './Row'
import { TextField } from './TextField'

export type FieldProps<FormValues extends FieldValues = FieldValues> = {
  name: Path<FormValues>
  label?: string
  info?: ReactNode
  disabled?: boolean
  input?: ReactElement<TextFieldProps>
  disabledSuffixElement?: ReactElement
}
export function Field<FormValues extends FieldValues = FieldValues>({
  label,
  info,
  name,
  input = <TextField<FormValues> />,
  disabledSuffixElement = (
    <Cell>
      <Icons.Lock color="secondary" />
    </Cell>
  ),
  disabled = false,
}: FieldProps<FormValues>) {
  const { errors } = useFormState<FormValues>({
    name,
  })
  const error = get(errors, name, null) as FieldError | null

  const { editable } = useFormContext()

  const isDisabled = disabled || !editable

  return (
    <Box width="100%">
      {label && (
        <InfoPopover
          content={info ? info : undefined}
          contentContainer={<Stack direction="row" margin="1rem" />}
          iconSize="small"
          colour="dark"
        >
          <FormLabel error={!!error} data-name={name}>
            {label}
          </FormLabel>
        </InfoPopover>
      )}

      <RowStack>
        {React.cloneElement(input, {
          name: name,
          disabled: isDisabled,
          variant: 'outlined',
        })}

        {isDisabled && disabledSuffixElement}
      </RowStack>
    </Box>
  )
}
