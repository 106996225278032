import { createFileRoute } from '@tanstack/react-router'
import { z } from 'zod'

import { CommercialPackagesPage } from '@tk/frontend/app/CommercialPackages'

const searchParams = z.object({
  filter: z.string().optional(),
})

export const Route = createFileRoute('/record-management/commercial-packages/')(
  {
    component: CommercialPackagesPage,
    validateSearch: searchParams.parse,
  }
)
