import { useMemo } from 'react'

import * as Icons from '@mui/icons-material'
import { Box, TextField } from '@mui/material'

import { Outputs, trpc, useUtils } from '@tk/frontend/api'
import {
  useBloombergMappingTableTelemetry,
  useBloombergMappingTelemetry,
} from '@tk/frontend/app/Bloomberg/telemetry'
import { TYPE_OPTIONS } from '@tk/frontend/app/Bloomberg/types'
import { ButtonLink, useEntitlement } from '@tk/frontend/primitives'
import { ActionButtonGroup } from '@tk/frontend/primitives/datagrid'
import { BooleanCell } from '@tk/frontend/primitives/datagrid/cells'
import {
  EditableDefaults,
  NamedBusinessObjectTable,
  NamedBusinessObjectTableProps,
} from '@tk/frontend/primitives/datagrid/table'
import { useClientFilter } from '@tk/frontend/primitives/datagrid/useClientFilter'
import { PageContentLayout } from '@tk/frontend/primitives/layout'

import { EntitlementCreateButton } from './EntitlementCreateButton'

type Dto = Outputs['bloomberg']['entitlements']['get']

type ExtraCols = NonNullable<NamedBusinessObjectTableProps<Dto>['extraColumns']>

type PackageActions = NonNullable<
  NamedBusinessObjectTableProps<Dto>['packageActions']
>

export function EntitlementsCrud() {
  useBloombergMappingTableTelemetry('bloomberg/entitlements')

  return (
    <PageContentLayout
      title="Bloomberg: Entitlements"
      controls={<EntitlementCreateButton />}
    >
      <EntitlementsCrudTable />
    </PageContentLayout>
  )
}

function EntitlementsCrudTable() {
  const { trackEditSave, trackEditStart } = useBloombergMappingTelemetry(
    'bloomberg/entitlements'
  )

  const utils = useUtils()

  const mutator = trpc.bloomberg.entitlements.upsert.useMutation({
    onMutate(variables) {
      trackEditSave('not-tracked', variables)
    },
    onSuccess() {
      utils.bloomberg.entitlements.invalidate()
    },
  })
  const [list] = trpc.bloomberg.entitlements.list.useSuspenseQuery()

  const extraCols = useMemo<ExtraCols>(() => {
    return [
      {
        type: 'singleSelect',
        field: 'type',
        headerName: 'Type',
        valueOptions: TYPE_OPTIONS,
        width: 150,
        editable: true,
        valueFormatter(value) {
          return TYPE_OPTIONS.find((t) => t.value === value)?.label
        },
      },
      {
        // TODO: fix space bar exiting edit mode when editing this field
        // Open issue: https://github.com/mui/mui-x/issues/5952
        type: 'boolean',
        field: 'allToSee',
        headerName: 'All To See?',
        width: 100,
        editable: true,
        renderCell(params) {
          return <BooleanCell value={params.value} />
        },
      },
    ]
  }, [])

  const packageActions = useMemo<PackageActions>(() => {
    return [
      {
        type: 'actions',
        field: 'nav-actions',
        flex: 1,
        align: 'left',
        getActions(params) {
          return [
            <ActionButtonGroup>
              <ButtonLink
                color="primary"
                startIcon={<Icons.TableRowsRounded />}
                variant="text"
                to="/bloomberg/records-mappings"
                search={{ 'initial-entitlement-ids': params.row.id }}
              >
                Mappings
              </ButtonLink>
            </ActionButtonGroup>,
          ]
        },
      },
    ]
  }, [])

  const canManage = useEntitlement('bloomberg-mappings.manage')

  const { filteredList, filterValue, setFilterValue } = useClientFilter(list)

  return (
    <Box display="flex" flexDirection="column" height="100%" width="100%">
      <TextField
        value={filterValue ?? ''}
        onChange={(e) => setFilterValue(e.target.value)}
        label="Filter"
        variant="filled"
        inputProps={{
          role: 'search',
        }}
        placeholder='You may use wildcards (%) to search for partial Name and Description. ie. "%ICAP%"'
        fullWidth
      />

      <NamedBusinessObjectTable<Dto>
        list={filteredList}
        onEditStart={trackEditStart}
        onEditSave={mutator.mutateAsync}
        extraColumns={extraCols}
        initialSortField="description"
        editable={canManage && EditableDefaults}
        packageActions={packageActions}
      />
    </Box>
  )
}
