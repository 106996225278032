import { createFileRoute } from '@tanstack/react-router'
import { z } from 'zod'

const searchParams = z
  .object({
    focus: z.number().optional(),
  })
  .optional()

export const Route = createFileRoute('/record-management/records/relink/')({
  validateSearch: searchParams.parse,
})
