import * as Icons from '@mui/icons-material'

import { MenuItemLink } from '@tk/frontend/primitives/MenuItemLink'
import { telemetry } from '@tk/frontend/telemetry'

export function ImportListButton() {
  return (
    <MenuItemLink
      to="/record-management/records/imports"
      startIcon={<Icons.List />}
      onClick={() => {
        telemetry.capture('gtk/record-import/list')
      }}
    >
      Imports List
    </MenuItemLink>
  )
}
