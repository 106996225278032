import { useMemo } from 'react'
import { useWatch } from 'react-hook-form'

import { Button, Stack, Typography } from '@mui/material'
import { useNavigate } from '@tanstack/react-router'

import { trpc } from '@tk/frontend/api'
import { RecordEditDto } from '@tk/frontend/app/Records/common/crud'
import {
  getFormComponents,
  usePromiseNotification,
} from '@tk/frontend/primitives'
import { DatePickerField } from '@tk/frontend/primitives/forms/DateTimePicker'
import { FieldInfo } from '@tk/frontend/primitives/forms/FieldInfo'

import { RECORD_STATUS_OPTIONS } from './constants'
import { RecordEditFieldsCore } from './RecordEditFieldsCore'
import { RecordRenameDto } from './types'

type Props = {
  onFinished: () => void
}

const Form = getFormComponents<RecordRenameDto>()
export function RenameForm({ onFinished }: Props) {
  const navigate = useNavigate()
  const progress = usePromiseNotification()
  const record = useWatch<RecordEditDto>()

  const form = Form.useForm({
    defaultValues: {
      newRecordName: record.name,
      newStatus: record.status,
      newSourceId: record.source?.id,
      cutoverDate: undefined,
    },
  })

  const { handleSubmit: _handleSubmit } = form

  const [lists] = RecordEditFieldsCore.useRecordReferenceLists()

  const renameMutation = trpc.records.rename.useMutation()

  const handleSubmit = useMemo(() => {
    return _handleSubmit(async (values) => {
      const promise = renameMutation.mutateAsync({
        id: record.id!,
        ...(values as any),
      })
      const result = await progress(promise, {
        progressMessage: 'Updating ' + record.name,
        successMessage: 'Updated to ' + values.newRecordName,
        failureMessage: 'Error updating ' + values.newRecordName,
      })
      navigate({
        to: '/record-management/records/edit/$id',
        params: { id: result.id },
      })

      onFinished?.()
    })
  }, [_handleSubmit, renameMutation, progress, onFinished, navigate, record])

  return (
    <Form.Provider {...form}>
      <Stack width="30rem" component="form" onSubmit={handleSubmit}>
        <Typography>Original Record</Typography>
        <Form.Field
          label="Status (Required)"
          name="newStatus"
          input={
            <Form.SelectField
              rules={Form.rules.required}
              options={RECORD_STATUS_OPTIONS}
            />
          }
        />

        <Typography>New Record</Typography>
        <Form.Field
          label="Name (Required)"
          name="newRecordName"
          input={
            <Form.TextField
              rules={Form.rules.required}
              autoFocus
              style={{ textTransform: 'uppercase' }}
            />
          }
        />
        <Form.Field
          label="Source (Required)"
          name="newSourceId"
          input={
            <Form.SelectField
              group
              rules={Form.rules.required}
              options={lists.source}
            />
          }
        />

        <Form.Field
          label="Cutover Date"
          name="cutoverDate"
          info={
            <FieldInfo
              title="Cutover Date:"
              details={
                <p>
                  Sets the Valid To Date for the original record and the Valid
                  From Date for the new record.
                  <br />
                  <br />
                  This may be used when you have a specific date where you would
                  like the history we distribute to switch between records
                </p>
              }
            />
          }
          input={<DatePickerField name="cutoverDate" />}
        />

        <Form.Row>
          <Button
            type="submit"
            disabled={!form.formState.isDirty || renameMutation.isPending}
          >
            Apply Rename
          </Button>
        </Form.Row>
      </Stack>
    </Form.Provider>
  )
}
