import { ToggleButton, ToggleButtonGroup } from '@mui/material'

import { useQueryParam } from '@tk/frontend/primitives/useQueryParam'

export type RetiredType = 'ALL' | 'ACTIVE' | 'RETIRED'

export function useRetiredFilter() {
  const [retiredFilter, setRetiredFilter] = useQueryParam<RetiredType>(
    'retired',
    { defaultValue: 'ACTIVE' }
  )

  return [retiredFilter, setRetiredFilter] as const
}

export function RecordMappingRetiredFilter() {
  const [value, onChange] = useRetiredFilter()

  return (
    <ToggleButtonGroup
      value={value}
      exclusive
      onChange={(e, value: RetiredType) => onChange(value)}
    >
      <ToggleButton value="ACTIVE" color="success">
        Active
      </ToggleButton>
      <ToggleButton value="RETIRED" color="warning">
        Retired
      </ToggleButton>
      <ToggleButton value="ALL" color="info">
        All
      </ToggleButton>
    </ToggleButtonGroup>
  )
}
