import { createFileRoute } from '@tanstack/react-router'
import { z } from 'zod'

import { ExportProgressList } from '@tk/frontend/app/Records/Export/ExportProgressList'

const searchParams = z
  .object({
    focus: z.string().optional(),
  })
  .optional()

export const Route = createFileRoute('/record-management/records/exports')({
  component: ExportProgressList,
  validateSearch: searchParams.parse,
})
