import { useMemo } from 'react'

import { RecordDto, RecordListingItemDto } from '@lib/pgs'

type UseValidDatesParams = {
  record: RecordDto
  selectedRecord: RecordListingItemDto | null
  cutoverDate?: Date
  recordOneIsNew: boolean
  clearExistingValidDates: boolean
}

export function useValidDates({
  record,
  selectedRecord,
  cutoverDate,
  recordOneIsNew,
  clearExistingValidDates,
}: UseValidDatesParams) {
  const {
    recordOneValidFromDate,
    recordOneValidToDate,
    recordTwoValidFromDate,
    recordTwoValidToDate,
  } = useMemo(() => {
    const recordValidFromDate = clearExistingValidDates
      ? undefined
      : record.validFromDate ?? undefined
    const recordValidToDate = clearExistingValidDates
      ? undefined
      : record.validToDate ?? undefined

    const selectedRecordValidFromDate = clearExistingValidDates
      ? undefined
      : selectedRecord?.validFromDate ?? undefined
    const selectedRecordValidToDate = clearExistingValidDates
      ? undefined
      : selectedRecord?.validToDate ?? undefined

    if (!cutoverDate) {
      return {
        recordOneValidFromDate: recordValidFromDate,
        recordOneValidToDate: recordValidToDate,
        recordTwoValidFromDate: selectedRecordValidFromDate,
        recordTwoValidToDate: selectedRecordValidToDate,
      }
    }

    if (recordOneIsNew) {
      return {
        recordOneValidFromDate: cutoverDate,
        recordOneValidToDate: recordValidToDate ?? undefined,
        recordTwoValidFromDate: selectedRecordValidFromDate,
        recordTwoValidToDate: cutoverDate,
      }
    } else {
      return {
        recordOneValidFromDate: recordValidFromDate,
        recordOneValidToDate: cutoverDate,
        recordTwoValidFromDate: cutoverDate,
        recordTwoValidToDate: selectedRecordValidToDate ?? undefined,
      }
    }
  }, [
    cutoverDate,
    record,
    selectedRecord,
    recordOneIsNew,
    clearExistingValidDates,
  ])

  return {
    recordOneValidFromDate,
    recordOneValidToDate,
    recordTwoValidFromDate,
    recordTwoValidToDate,
  }
}
