import { useCallback } from 'react'

import {
  getDefaultGridFilterModel,
  GridFilterModel,
  GridLogicOperator,
  GridSlotsComponentsProps,
} from '@mui/x-data-grid-premium'

import { useQueryParam } from '@tk/frontend/primitives/useQueryParam'

type SetFilterModelParam =
  | GridFilterModel
  | ((oldModel: GridFilterModel | undefined) => GridFilterModel)

const DefaultGridFilterModel = getDefaultGridFilterModel()

export function useFilterModel(name = 'grid-filters'): {
  model: GridFilterModel | undefined
  setModel: (model: SetFilterModelParam) => void
  slotProps: GridSlotsComponentsProps
} {
  const [model, setModel] = useQueryParam<GridFilterModel | undefined>(name, {
    // FIXME: this produces a tidier URI than JsonParam, BUT it's also not good.
    // We should have a dedicated QueryParams setup which does a fundamental
    //  transformation of GridFilterModel to a nice-ish URL.
    decode: (value) => {
      if (typeof value !== 'string') {
        return undefined
      }

      return JSON.parse(window.atob(value))
    },
    encode: (value) => {
      if (!value || value.items.length === 0) {
        return undefined
      }

      return window.btoa(JSON.stringify(value))
    },
    defaultValue: DefaultGridFilterModel,
  })

  return {
    model,
    setModel: useCallback(
      (model) => setModel(model as GridFilterModel),
      [setModel]
    ),
    slotProps,
  }
}

const slotProps: GridSlotsComponentsProps = {
  filterPanel: {
    columnsSort: 'asc',
    logicOperators: [GridLogicOperator.And],
  },
}
