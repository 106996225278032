import { createFileRoute } from '@tanstack/react-router'

import { RrnStructureEditPage } from '@tk/frontend/app/Multibrand/RrnStructureCrud'
import { parseParamAsNumber } from '@tk/frontend/primitives/routing'

export const Route = createFileRoute(
  '/multibrand/root-record-name-structure/edit/$id'
)({
  params: parseParamAsNumber('id'),
  component: RrnStructureEditPage,
})
