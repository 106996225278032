import { ReactNode, Suspense, useCallback } from 'react'

import * as Icons from '@mui/icons-material'
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
} from '@mui/material'
import { create, useModal } from '@parameta/nice-modal-react'
import { keepPreviousData } from '@tanstack/react-query'

import { Outputs, trpc } from '@tk/frontend/api'
import { useRecordsQueryParams } from '@tk/frontend/app/Records/useRecordsQueryParams'
import { usePromiseNotification } from '@tk/frontend/primitives'

import { DownloadButton } from './DownloadButton'
import { Fids } from './types'

type SalesSnapModalRendererModel = {
  type: 'export'
  reportId: number
}

type ExportSalesSnapViewProps = SalesSnapModalRendererModel

const reportStatusLabels: Record<
  Outputs['extracta']['getReportById']['status'],
  { label: string; icon: ReactNode }
> = {
  ERROR: {
    label: 'Extract failed',
    icon: <Icons.Error color="error" />,
  },
  FINISHED: {
    label: 'Ready to download',
    icon: <Icons.Check color="success" />,
  },
  IN_PROGRESS: {
    label: 'Working...',
    icon: <CircularProgress size={16} thickness={10} />,
  },
  MERGING_FILES: {
    label: 'Merging files...',
    icon: <CircularProgress size={16} thickness={10} />,
  },
  QUERY_FINISHED: {
    label: 'Query finished, moving on...',
    icon: <CircularProgress size={16} thickness={10} />,
  },
  SUBMITTED: {
    label: 'Submitted, moving on...',
    icon: <CircularProgress size={16} thickness={10} />,
  },
}

const ExportSalesSnapView = ({ reportId }: ExportSalesSnapViewProps) => {
  const modal = useModal()

  const { data: report } = trpc.extracta.getReportById.useQuery(
    {
      id: reportId,
    },
    {
      refetchInterval(data) {
        if (
          data.state.data?.status &&
          ['FINISHED', 'ERROR'].includes(data.state.data?.status)
        ) {
          return false
        }

        return 500
      },
      placeholderData: keepPreviousData,
    }
  )

  return (
    <Dialog open={modal.visible} onClose={modal.hide} fullWidth maxWidth="xs">
      <DialogTitle>
        Sales Snap
        <IconButton
          sx={{ position: 'absolute', right: 8, top: 8 }}
          onClick={modal.hide}
        >
          <Icons.Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Stack direction="row" alignItems="center">
          {report?.status && reportStatusLabels[report.status].icon}
          <span>
            {report?.status && reportStatusLabels[report.status].label}
          </span>
        </Stack>

        {report?.status === 'FINISHED' && (
          <DialogActions>
            <DownloadButton id={reportId} />
          </DialogActions>
        )}

        {report?.status === 'ERROR' && (
          <p>"{report?.statusMessage ?? report?.status}"</p>
        )}
      </DialogContent>
    </Dialog>
  )
}

export const SalesSnapModalRenderer = create<SalesSnapModalRendererModel>(
  (model) => {
    if (model.type === 'export') {
      return (
        <Suspense fallback="Fetching report...">
          <ExportSalesSnapView {...model} />
        </Suspense>
      )
    }

    return null
  }
)

export const useExportSalesSnap = () => {
  const createExtractMutation = trpc.extracta.createSalesSnap.useMutation()
  const notify = usePromiseNotification()
  const records = useRecordsQueryParams()

  const startExport = useCallback(
    async (fids: Fids) => {
      const mappedFids = fids.map((f) => {
        return {
          id: f.fidId,
          header: f.fidName,
        }
      })
      const res = await notify(
        createExtractMutation.mutateAsync({
          recordParams: records.query,
          fids: mappedFids,
        }),
        {
          progressMessage: 'Starting extract...',
          successMessage: 'Extract started',
          failureMessage: 'Extract failed',
        }
      )

      return res
    },
    [createExtractMutation, notify, records]
  )

  return {
    startExport,
    mutation: createExtractMutation,
  }
}
