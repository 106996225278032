export function parseParamAsNumber<T extends Record<string, string>>(
  paramName: keyof T
) {
  return {
    parse: (params: T) =>
      ({
        [paramName]: parseInt(params[paramName] as string, 10),
      } as Record<keyof T, number>),
    stringify: (params: Record<keyof T, number>) =>
      ({
        [paramName]: params[paramName].toString(),
      } as Record<keyof T, string>),
  }
}
