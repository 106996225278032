import { ReactNode, Suspense, useState } from 'react'

import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Paper,
  Stack,
  Typography,
} from '@mui/material'
import { ErrorComponentProps } from '@tanstack/react-router'

import { Colours } from '@lib/theme'

export type PaperSectionSpinnerProps = {
  size?: 'small' | 'medium'
}

export const PaperSectionSpinner = ({
  size = 'medium',
}: PaperSectionSpinnerProps) => {
  let sizeVal = '5rem'
  if (size === 'small') {
    sizeVal = '3rem'
  }

  return (
    <Box
      display="flex"
      width="100%"
      padding="4rem"
      justifyContent="center"
      alignItems="center"
    >
      <CircularProgress size={sizeVal} />
    </Box>
  )
}

export type FullscreenLoaderProps = {
  message: string
}

export const FullscreenLoader = ({ message }: FullscreenLoaderProps) => {
  const [renderPedro] = useState(() => Math.floor(Math.random() * 100) === 0)
  return (
    <Backdrop open sx={{ backgroundColor: Colours.BgShade.Blue }}>
      <Paper>
        <Stack alignItems="center" margin="10rem">
          {renderPedro ? (
            <div
              style={{
                width: '250px',
                height: '250px',
                borderRadius: '50%',
                overflow: 'hidden',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                border: '5px solid black',
              }}
            >
              <img
                src="assets/pedro.webp"
                alt="Pedro is loading"
                style={{
                  width: '260px',
                  height: '260px',
                }}
              />
            </div>
          ) : (
            <CircularProgress thickness={4} />
          )}
          <Typography variant="h6" color="primary">
            {message}
          </Typography>
        </Stack>
      </Paper>
    </Backdrop>
  )
}

export type FullscreenErrorProps = {
  error: Error | string
  onReset: () => void
  refreshText?: string
  refreshButtonText?: string
}

export const OnScreenError = ({
  error,
  onReset,
  refreshButtonText,
  refreshText,
}: FullscreenErrorProps) => {
  const message =
    typeof error === 'string'
      ? error
      : JSON.stringify((error as any).shape?.data, null, 2) ?? error.message

  return (
    <Stack alignItems="center" margin="10rem">
      <Typography variant="h1">Oh no! Something went wrong</Typography>

      <Typography
        variant="body2"
        sx={{
          whiteSpace: 'pre-wrap',
          maxWidth: '40rem',
          overflowWrap: 'anywhere',
        }}
      >
        {message}
      </Typography>

      <Typography variant="body1">
        {refreshText ?? 'Please try again'}
      </Typography>

      <Button variant="contained" onClick={onReset}>
        {refreshButtonText ?? 'Refresh'}
      </Button>
    </Stack>
  )
}

export const FullscreenError = ({ error, reset }: ErrorComponentProps) => {
  return (
    <Backdrop open sx={{ backgroundColor: Colours.BgShade.Blue }}>
      <Paper>
        <OnScreenError error={error} onReset={reset} />
      </Paper>
    </Backdrop>
  )
}

export const NotFoundError = ({ error, reset }: ErrorComponentProps) => {
  return (
    <Backdrop open sx={{ backgroundColor: Colours.BgShade.Blue }}>
      <Paper>
        <OnScreenError
          error={error}
          onReset={reset}
          refreshText=""
          refreshButtonText="Go Back"
        />
      </Paper>
    </Backdrop>
  )
}

export type SuspenseBoundaryWithBlockingSpinnerProps = {
  children: ReactNode
}
export function SuspenseBoundaryWithBlockingSpinner({
  children,
}: SuspenseBoundaryWithBlockingSpinnerProps) {
  return (
    <Suspense fallback={<FullscreenLoader message="Loading..." />}>
      {children}
    </Suspense>
  )
}

export type SuspenseBoundaryLocalProps = {
  children: ReactNode
  width?: string | number
  height?: string | number
}
export function SuspenseBoundaryLocal({
  children,
  width,
  height,
}: SuspenseBoundaryLocalProps) {
  return (
    <Suspense
      fallback={
        <Stack
          alignItems="center"
          justifyContent="center"
          margin="10rem"
          width={width}
          height={height}
        >
          <CircularProgress thickness={4} />
        </Stack>
      }
    >
      {children}
    </Suspense>
  )
}
