import { ToggleButton, ToggleButtonGroup } from '@mui/material'

import { useQueryParam } from '@tk/frontend/primitives/useQueryParam'

export type ImportPreviewRowFilterState = 'all' | 'errors'

export function usePreviewRowFilterParam() {
  const [value, setValue] = useQueryParam<ImportPreviewRowFilterState>(
    'row-filter',
    {
      defaultValue: 'all',
    }
  )

  return [value, setValue] as const
}

export function ImportPreviewRowFilter() {
  const [value, onChange] = usePreviewRowFilterParam()

  return (
    <ToggleButtonGroup
      value={value}
      exclusive
      onChange={(e, value: ImportPreviewRowFilterState) => onChange(value)}
    >
      <ToggleButton value="all" color="info">
        All
      </ToggleButton>
      <ToggleButton value="errors" color="warning">
        Errors
      </ToggleButton>
    </ToggleButtonGroup>
  )
}
