import { Button, Paper, Stack, Typography } from '@mui/material'
import { useNavigate } from '@tanstack/react-router'

import { trpc, useUtils } from '@tk/frontend/api'
import { usePromiseNotification } from '@tk/frontend/primitives'

export function ExitAssumedRole() {
  const notify = usePromiseNotification()
  const [me] = trpc.admin.userRoles.me.useSuspenseQuery()
  const navigate = useNavigate()
  const utils = useUtils()
  const roleAssumer = trpc.admin.userRoles.assume.useMutation({
    onSuccess: async () => {
      await utils.admin.invalidate()
      navigate({ to: '/admin/roles' })
    },
  })

  async function handleExitRole() {
    await notify(
      roleAssumer.mutateAsync({
        type: 'revert',
      }),
      {
        progressMessage: 'Exiting assumed role',
        successMessage: 'Exited assumed role',
        failureMessage: 'Failed exiting assumed role',
      }
    )
  }

  const isAssumed = me.isAssumed
  if (!isAssumed) {
    return null
  }

  return (
    <div
      style={{ position: 'absolute', zIndex: 1300, top: '5px', left: '5px' }}
    >
      <Paper sx={{ width: 'fit-content' }}>
        <Stack padding="0.5rem" direction="row" alignItems="center">
          <Typography whiteSpace="nowrap">
            Role: <strong>{me.role}</strong>
          </Typography>

          <Button
            tabIndex={-1}
            onClick={handleExitRole}
            style={{ whiteSpace: 'nowrap' }}
            variant="outlined"
            color="warning"
          >
            Exit Role
          </Button>
        </Stack>
      </Paper>
    </div>
  )
}
