import { FieldValues, UseControllerProps } from 'react-hook-form'

export type Rules<TFormValues extends FieldValues = FieldValues> =
  UseControllerProps<TFormValues>['rules']

export const getRules = <TFormValues extends FieldValues = FieldValues>() => {
  const Required: Rules<TFormValues> = Object.freeze({
    required: {
      value: true,
      message: 'Required',
    },
  })

  return {
    required: Required,
  }
}
