import { ReactNode } from 'react'

import type { Roles } from '@tk/api'

import { notFound, Outlet } from '@tanstack/react-router'

import { useEntitlement } from './roles'
import { KnownFeature, useFeature } from './useFeature'

export type WhenProps = {
  can?: Roles.Entitlement | Roles.Entitlement[]
  flag?: KnownFeature
  children: ReactNode
  whenNot?: ReactNode | (() => void)
}

export function When({ can, flag, whenNot = null, children }: WhenProps) {
  const userCan = When.useEntitlement(can)
  const featureEnabled = useFeature(flag)

  if (userCan && featureEnabled) {
    return <>{children}</>
  } else if (typeof whenNot === 'function') {
    whenNot()
    return null
  } else if (whenNot) {
    return <>{whenNot}</>
  } else {
    return null
  }
}

When.useEntitlement = useEntitlement

export function RouterWhen(props: Omit<WhenProps, 'whenNot' | 'children'>) {
  return (
    <When
      {...props}
      whenNot={() => {
        throw notFound()
      }}
    >
      <Outlet />
    </When>
  )
}
