import { useRef } from 'react'
import { Path } from 'react-hook-form'

import { GridColumnVisibilityModel } from '@mui/x-data-grid-premium'

import { useQueryParam } from '@tk/frontend/primitives/useQueryParam'

export type ColumnVisibility<T extends object> = {
  [key in Path<T>]?: boolean
}

export function useColumnVisibilityModel<T extends object>(
  defaultVisibility: ColumnVisibility<T>
) {
  const [model, setModel] = useQueryParam<GridColumnVisibilityModel>(
    'columns',
    {
      defaultValue: useRef(defaultVisibility as GridColumnVisibilityModel)
        .current,
    }
  )

  return { model, setModel }
}
