import {
  FieldValues,
  Path,
  useController,
  UseControllerProps,
} from 'react-hook-form'

import type { DateRange } from '@mui/x-date-pickers-pro'

import { DateRangePicker, LocalizationProvider } from '@mui/x-date-pickers-pro'
import { AdapterLuxon } from '@mui/x-date-pickers-pro/AdapterLuxon'

interface DateRangeProps<FormValues extends FieldValues = FieldValues> {
  name?: Path<FormValues>
  startLabel?: string
  endLabel?: string
  rules?: UseControllerProps<FormValues>['rules']
  autoFocus?: boolean
}

export function DateRange<FormValues extends FieldValues = FieldValues>({
  name,
  startLabel,
  endLabel,
  autoFocus,
  rules,
}: DateRangeProps<FormValues>) {
  const { field } = useController<FormValues>({
    name: name ?? ('' as any),
    rules,
    shouldUnregister: true,
  })

  return (
    <LocalizationProvider
      dateAdapter={AdapterLuxon}
      localeText={{
        start: startLabel,
        end: endLabel,
      }}
    >
      <DateRangePicker
        {...field}
        calendars={3}
        timezone="utc"
        autoFocus={autoFocus}
        slotProps={{
          textField: { size: 'small' },
          fieldSeparator: { children: 'to' },
        }}
      />
    </LocalizationProvider>
  )
}
